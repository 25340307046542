import type { FeatureOptionItem } from 'website/components/types'
import { keys } from "lodash-es";

export const parseOptions = (options: Record<string, API.VehicleOption[]>): FeatureOptionItem[] => {
  const parsedOptions = Object.values(options).reduce((res: string[], items) => {
    const filteredItems = items.reduce((res: string[], { isEnabled, optionDescription }) => {
      if (isEnabled) {
        res.push(optionDescription)
      }
      
      return res
    }, [])
    
    if (filteredItems.length > 0) {
      return [...res, ...filteredItems]
    }
    
    return  res
  }, [])
  
  if (parsedOptions.length === 0) {
    return []
  }

  return [{
    name: 'Options',
    options: parsedOptions
  }]
}

export const parseFeatures = (features: Record<string, API.VehicleFeature[]>): FeatureOptionItem[] => {
  return Object.keys(features).reverse().reduce((res: FeatureOptionItem[], feature) => {
    const filteredFeature = features[feature]
      .reduce((res: string[], { isEnabled, name }) => {
        if (isEnabled) {
          res.push(name)
        }
        return res
      }, [])

    if (filteredFeature.length > 0) {
      res.push({
        name: feature,
        options: filteredFeature
      })
    }

    return res
  }, [])
}
