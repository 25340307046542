import { createContext } from 'react'
import { makeAutoObservable } from 'mobx'

import { TransactionTypeId, PaymentStatusId, WorkflowStateName } from 'api/types'
import { WorkflowApiProvider } from 'api/workflow.api'
import { PaymentsApiProvider } from 'api/payments.api'
import { WizardInstance as Wz } from 'components/wizard/wizard.store'
import { WorkflowStepName as StepName } from 'api/types/workflow.types'
import { SummaryInstance as Summary } from 'store/summary'

import { AccessInfo, PaymentDetail, PayTypes, PlaidData } from './types'
import { BANK_PAID, CLIENT_SECRET, PAID_CARD } from 'website/constants/localStorageKeys'

const DEFAULT_CHOSEN_PAY = {
  Deposit: false,
  Cash: false,
  ACH: false,
  Card: false,
  Cryptocurrency: false
}

const DEFAULT_PAYMENT_STATUS = {
  Deposit: PaymentStatusId.Completed,
  ACH: PaymentStatusId.Pending,
  Card: PaymentStatusId.Pending,
  Cryptocurrency: PaymentStatusId.Pending
}

class Payments {
  isInitialized = false
  plaidData: PlaidData | null = null
  clientSecret = process.env.REACT_APP_CLIENT_SECRET_INTENT ?? ''
  stripeAccount = ''
  paymentDetails: Partial<Record<PayTypes, PaymentDetail>> = {}
  isCardModalShown = false
  remainingAmountToSplit: number = 0

  chosenPay: Record<PayTypes, boolean> = {
    ...DEFAULT_CHOSEN_PAY
  }

  paymentStatus: Record<Exclude<keyof typeof PayTypes, 'Cash'>, PaymentStatusId> = {
    ...DEFAULT_PAYMENT_STATUS
  }

  get isSingleEditablePayment (): boolean {
    return Object.values(this.paymentDetailsWithoutDeposit ?? {}).length === 1
  }

  get paymentDetailsWithoutDeposit (): Partial<Record<PayTypes, PaymentDetail>> {
    const { Deposit, ...paymentDetails } = this.paymentDetails
    return paymentDetails
  }

  get isSplitPayment (): boolean {
    return Object.keys(this.paymentDetails ?? {}).length > 1
  }

  hasSingleIncompletePayment = (): boolean => {
    return Object.values(this.paymentDetails)
      .filter(detail => toNumber(detail.amount) === 0)
      .length === 1
  }

  calcRemainingAmountToSplit = (): number => {
    const result = Object.values(this.paymentDetails)
      .map(detail => Math.abs(toNumber(detail.amount)))
      .reduce((acc, amount) => acc - amount, Summary.totalSumToPay)

    const decimalResult = Number(result.toFixed(2))
    this.remainingAmountToSplit = decimalResult

    return decimalResult
  }

  get cardAmount (): number {
    return this.paymentDetails.Card?.amount ?? 0
  }

  get isCardPaymentAmountOverLimit (): boolean {
    const { maxCardPaymentAmount } = Summary
    return this.cardAmount > maxCardPaymentAmount
  }

  get isSumToPayValid (): boolean {
    const paymentDetails = this.paymentDetails
    const { totalSumToPay } = Summary

    if (Object.values(paymentDetails).length === 0) {
      return false
    }

    if (this.isCardPaymentAmountOverLimit) {
      return false
    }

    const paymentDetailsAmount = Object.values(paymentDetails ?? {})
      .reduce((acc, currentValue) => acc + Math.abs(currentValue?.amount ?? 0), 0)

    return Number(totalSumToPay.toFixed(2)) === Number(paymentDetailsAmount.toFixed(2))
  }

  get isAllFinanced (): boolean {
    const lenderInfo = Wz.getLenderDecisionDetails()
    if (lenderInfo != null) {
      return (lenderInfo.downPayment === 0 || lenderInfo.downPayment == null) && (lenderInfo.monthlyPayment > 0)
    }

    return false
  }

  setupPayments = async (): Promise<void> => {
    this.isInitialized = false
    const dealId = Wz.workflow?.id

    if (dealId == null) {
      /**
       * dealId absence here is a rude error of the flow.
       * That is why we return even without marking as initialized which leads to blank page
       * If this is a real case it should be though better how to avoid or handle this
       */
      return
    }

    await Summary.getFreshDealSummary(dealId.toString())
    await Wz.loadFreshWorkflowById(dealId)

    const hasPlaidToken = (this.plaidData?.linkToken ?? '').length > 0
    const isDealNotCompleted = Wz.workflow?.workflowState !== WorkflowStateName.Completed

    if (isDealNotCompleted) {
      // Initialize Stripe client for credit card payments.
      await this.setupStripeAccount()

      // Initialize Plaid client for bank transfer (ACH) payments.
      if (!hasPlaidToken) {
        await this.setupPlaidLink()
      }
    }

    /**
     * Restore information about selected pay types
     * from Workflow instance
     */

    const payments = Wz.getPaymentDetails()
    const paymentDetails: Partial<Record<PayTypes, PaymentDetail>> = {}
    const paymentStatus = { ...DEFAULT_PAYMENT_STATUS }
    const chosenPay = { ...DEFAULT_CHOSEN_PAY }

    for (const payment of payments) {
      const {
        amount, amountPayed, transactionTypeId,
        paymentStatusId
      } = payment

      const details = { amount, transactionTypeId, paymentStatusId }
      const paymentType = Object
        .entries(TransactionTypeId)
        .find(([_, value]) => value === transactionTypeId)?.[0]

      if (isPayType(paymentType)) {
        paymentDetails[paymentType] = details
        chosenPay[paymentType] = true

        if (paymentType !== PayTypes.Cash && (amountPayed > 0)) {
          paymentStatus[paymentType] = paymentStatusId
        }
      }
    }

    this.paymentDetails = paymentDetails
    this.paymentStatus = paymentStatus
    this.chosenPay = chosenPay

    this.isInitialized = true
  }

  setupPlaidLink = async (): Promise<void> => {
    try {
      this.plaidData = await PaymentsApiProvider.linkPlaid()
    } catch (err) {
      console.warn('Plaid initialization failed. It seems, bank transfer is already paid.')
    }
  }

  setupStripeAccount = async (): Promise<void> => {
    try {
      const response: any = await PaymentsApiProvider.stripeAccount()
      this.stripeAccount = response.accountId
    } catch (err) {
      console.warn('Stripe initialization failed. It seems, card payment is already done.')
    }
  }

  showCardPaymentModal = (): void => {
    this.isCardModalShown = true
  }

  hideCardPaymentModal = (): void => {
    this.isCardModalShown = false
  }

  changeIsPayed = (
    name: Exclude<PayTypes, PayTypes.Cash>,
    status: PaymentStatusId
  ): void => {
    this.paymentStatus[name] = status
  }

  changeChosenPay = (name: PayTypes, value: boolean): void => {
    this.chosenPay = { ...this.chosenPay, [name]: value }
  }

  changeAmountPayment = (name: PayTypes, value: string | number): void => {
    const paymentData = this.paymentDetails[name]

    if (paymentData != null) {
      paymentData.amount = Number(value)
    }
  }

  clearEditablePaymentsAmount = (): void => {
    const paymentDetails = { ...this.paymentDetailsWithoutDeposit }

    for (const payment of Object.values(paymentDetails)) {
      payment.amount = 0
    }

    this.paymentDetails = this.paymentDetails.Deposit != null
      ? {
          [PayTypes.Deposit]: this.paymentDetails.Deposit,
          ...paymentDetails
        }
      : paymentDetails
  }

  changePaymentDetails = (name: PayTypes, paymentDetails: PaymentDetail, checked: boolean): void => {
    const isPreviousSinglePayment = this.isSingleEditablePayment

    if (checked) {
      this.paymentDetails[name] = paymentDetails
    } else {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.paymentDetails[name]
    }

    this.changeChosenPay(name, checked)

    if (name === PayTypes.Deposit) {
      return
    }

    if (isPreviousSinglePayment && !this.isSingleEditablePayment) {
      this.clearEditablePaymentsAmount()
    }

    this.calcRemainingAmountToSplit()

    if (this.isSingleEditablePayment) {
      this.clearEditablePaymentsAmount()
      this.calcRemainingAmountToSplit() // need this to get updated remaining amount to split
      const payType = Object.keys(this.paymentDetailsWithoutDeposit)[0] as PayTypes
      const remainingSum = this.remainingAmountToSplit
      const sumToPay = (payType === PayTypes.Card)
        ? Math.min(remainingSum, Summary.maxCardPaymentAmount)
        : remainingSum

      this.changeAmountPayment(payType, sumToPay)
      this.calcRemainingAmountToSplit() // recalc remaining immediately for single input
    }
  }

  payWithCryptocurrency = async (): Promise<void> => {
    const { redirectUrl } = await PaymentsApiProvider.cryptoCharge()
    window.location.replace(redirectUrl)
  }

  postIntentAction = async (): Promise<void> => {
    const response: any = await PaymentsApiProvider.intentPayments()

    localStorage.setItem(CLIENT_SECRET, response?.clientSecret)
    this.clientSecret = response.clientSecret
  }

  postChargeAction = async (accessInfo: AccessInfo): Promise<void> => {
    await PaymentsApiProvider.chargePayments(accessInfo)
  }

  updateWorkflowPayments = async (paymentDetails?: PaymentDetail[]): Promise<void> => {
    const nextPaymentDetails = (paymentDetails != null)
      ? filterPaymentDetails([...paymentDetails])
      : filterPaymentDetails(Object.values(this.paymentDetails))

    const data = { paymentDetails: nextPaymentDetails }

    await WorkflowApiProvider.paymentsWorkflow(data)
  }

  isPaid = (name: PayTypes): boolean => {
    if (Wz.isCompleted(StepName.Payments)) {
      return true
    }

    const payments = Wz.getPaymentDetails()
    const payment = payments.find(item => item.transactionTypeId === TransactionTypeId[name])

    if (payment === undefined) {
      return false
    }

    return payment.paymentStatusId !== PaymentStatusId.Pending
  }

  /**
   * In some cases payments are already finished using
   * third-party services, but our API don't know about it yet.
   * In these cases we are using confirmations from third-party
   * services, that are stored to localStorage.
   */
  isPaidLocal = (paymentMethodId: number): boolean => {
    switch (paymentMethodId) {
      case TransactionTypeId.Card: {
        const cardPaid = localStorage.getItem(PAID_CARD)
        return (cardPaid === 'true') || (this.paymentStatus.Card > PaymentStatusId.Pending)
      }
      case TransactionTypeId.ACH: {
        const bankPaid = localStorage.getItem(BANK_PAID)
        return (bankPaid === 'true') || (this.paymentStatus.ACH > PaymentStatusId.Pending)
      }
      case TransactionTypeId.Cryptocurrency:
        return this.paymentStatus.Cryptocurrency > PaymentStatusId.Pending
      default:
        return false
    }
  }

  constructor () {
    makeAutoObservable(this)
  }
}

export const PaymentsInstance = new Payments()
export const PaymentsCTX = createContext(PaymentsInstance)

export default PaymentsCTX

// ========================================== //

const filterPaymentDetails = (paymentDetails: PaymentDetail[]): PaymentDetail[] => (
  paymentDetails
    .slice()
    .sort((a, b) => a.transactionTypeId - b.transactionTypeId)
    .filter(({ transactionTypeId }) => transactionTypeId !== TransactionTypeId.Deposit)
    .filter(({ amount }) => amount > 0)
)

export function isPayType (name: any): name is PayTypes {
  return ((PayTypes as any)[name]) !== undefined
}

export const toNumber = (value?: number | string): number => {
  return (typeof value === 'number') ? Number(value) : 0
}
