import type { FC } from 'react'
import get from 'lodash-es/get'
import merge from 'lodash-es/merge'
import { UITools } from '@carfluent/common'

import { componentMapper } from 'website/components/DynamicComponent'
import Text from 'website/components/Text'
import { ListProps, SupportedComponents } from 'website/components/types'

import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { useStyles } from './styles'

const { cn } = UITools

const List: FC<ListProps> = ({
  itemType = SupportedComponents.DynamicComponent,
  itemKey,
  items,
  template,
  layout,
  className,
  variant,
  nameInLayout,
  itemComponentProps,
  listName,
  listNameConfig
}) => {
  const styles = useStyles()
  const componentStylesCls = useComponentStyles(SupportedComponents.List, variant)
  const templateCls = useLayoutStyles({ template, layout })

  return (
    <div className={cn(nameInLayout ?? SupportedComponents.List, componentStylesCls.root, className)}>
      <div className={cn(componentStylesCls.content, templateCls)}>
        {listName != null && (
          <Text className={styles.listName} value={listName} config={listNameConfig} />)}

        {items?.map((item, i) => {
          const { nameInLayout: itemName, ...otherProps } = item
          const key = get(item, itemKey ?? 'id') ?? itemName ?? i
          const type = item.type ?? itemType

          return (
            <div key={key} className={styles.listItem}>
              {componentMapper(key, type, { [type]: merge(otherProps, itemComponentProps) })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default List
