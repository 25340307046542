import { applyValidators, omitNotNumbers } from '@carfluent/common'

import type { FullAddressParts } from 'components/AddressAutocomplete/types'
import type { ConditionData, OptionalData } from '../types'
import {
  Errors,
  ErrorsMsg,
  BIRTHDATE_RANGE_START,
  BIRTHDATE_RANGE_END,
  MAX_AMOUNT,
  MIN_AMOUNT,
  ValidationLength
} from '../constants'
import {
  exactLength,
  greaterThanOrEqual,
  isEmailValid,
  isNameValid,
  isRequired,
  isRequiredOrNot,
  isValidDateOrEmpty,
  lessThanOrEqual,
  greaterThanOrEqualDateForStingDate,
  lessThanOrEqualDateForStingDate,
  addressData as addressDataUtil,
  isDateRequired,
  isConditionExist
} from '../rules'

const requiredValidators = [
  isRequired(Errors.Empty)
]

const firstNameValidators = [
  ...requiredValidators,
  isNameValid(Errors.OnlyLatin),
  greaterThanOrEqual(ErrorsMsg.minName, ValidationLength.NAME_MIN),
  lessThanOrEqual(Errors.LongName, ValidationLength.FIRST_NAME_MAX)
]

const lastNameValidators = [
  ...requiredValidators,
  isNameValid(Errors.OnlyLatin),
  greaterThanOrEqual(ErrorsMsg.minName, ValidationLength.NAME_MIN),
  lessThanOrEqual(Errors.LongName, ValidationLength.LAST_NAME_MAX)
]

const emailValidators = [
  ...requiredValidators,
  greaterThanOrEqual(Errors.ShortEmail, ValidationLength.EMAIL_SHORT),
  lessThanOrEqual(Errors.LongEmail, ValidationLength.EMAIL_MAX),
  isEmailValid(Errors.Email)
]

const phoneNumberValidators = [
  ...requiredValidators,
  exactLength(ErrorsMsg.phoneNumber, ValidationLength.PHONE_NUMBER)
]

export const dateTimeValidators = [
  isValidDateOrEmpty(Errors.InvalidDate)
]

export const birthDateValidators = [
  isDateRequired(Errors.InvalidDate),
  greaterThanOrEqualDateForStingDate(ErrorsMsg.birthdayMin, BIRTHDATE_RANGE_END),
  lessThanOrEqualDateForStingDate(ErrorsMsg.birthdayMax, BIRTHDATE_RANGE_START)
]

export const addressDataValidators = [
  addressDataUtil(Errors.InvalidAddress)
]

export const numberValidators = [
  ...requiredValidators
]

export const amountValidators = [
  ...numberValidators,
  greaterThanOrEqual(ErrorsMsg.minAmount, MIN_AMOUNT),
  lessThanOrEqual(ErrorsMsg.maxAmount, MAX_AMOUNT)
]

export const conditionValidators = [
  isConditionExist(Errors.InvalidCondition)
]

export const conditionWithSubConditionValidators = [
  isConditionExist(Errors.InvalidCondition, true)
]

export const aptValidators = [
  lessThanOrEqual(ErrorsMsg.maxAmount, ValidationLength.APT_MAX)
]

export const number = (val?: string): string | null => applyValidators(
  val, numberValidators
)

export const email = ({ value, isRequired = true }: OptionalData): string | null => applyValidators(
  value,
  (isRequired || Boolean(value))
    ? emailValidators
    : [isRequiredOrNot(isRequired, Errors.Empty)]
)

export const firstName = (val?: string): string | null => applyValidators(
  val, firstNameValidators
)

export const lastName = (val?: string): string | null => applyValidators(
  val, lastNameValidators
)

export const phoneNumber = (val?: string): string | null => applyValidators(
  omitNotNumbers(val), phoneNumberValidators
)

export const dateTime = (val?: string): string | null => applyValidators(
  val, dateTimeValidators
)

export const birthDate = (val?: string): string | null => applyValidators(
  val, birthDateValidators
)

export const addressData = (val?: FullAddressParts): string | null => applyValidators(
  val, addressDataValidators
)

export const condition = (val?: ConditionData): string | null => applyValidators(
  val, conditionValidators
)

export const conditionWithSubCondition = (val?: ConditionData): string | null => applyValidators(
  val, conditionWithSubConditionValidators
)

export const string = (val?: string): string | null => applyValidators(
  val, requiredValidators
)

export const amount = (val?: string): string | null => applyValidators(
  Number(omitNotNumbers(val ?? '0')), amountValidators
)

export const apt = (val?: string): string | null => applyValidators(
  val, aptValidators
)
