const commonStyles = `
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  padding: 0px 16px 40px;
  grid-template-columns: 1fr;

  .DealerInfoFragment {
    .phone-block {
      margin-bottom: 16px;
    }

    span {
      font-weight: 500;
    }
  }

  .Map {
    height: 224px;
    margin: 0px -16px;
  }

  @media (min-width: 768px) {
    padding: 0px 40px 40px;
  }

  @media (min-width: 600px) {
    .Map {
      height: 410px;
      margin: 0px;
    }
  }
`

export default {
  DealerInfo: {
    default: {
      content: `
        ${commonStyles}
        grid-template-columns: 1fr;
        grid-template-areas: 'Title' 'DealerInfoFragment' 'Schedule' 'Map';

        @media (min-width: 1100px) {
          grid-row-gap: 24px;
          grid-template-columns: 1fr minmax(670px, 1fr);
          padding: 0px max(40px, calc((100vw - 1440px) / 2)) 40px;
          grid-template-areas: 'Title Map' 'DealerInfoFragment Map' 'Schedule Map' '. Map';

          .Map > div {
            border-radius: 12px;
          }
        }
      `
    },
    multiDealership: {
      content: `
        ${commonStyles}
        grid-template-rows: min-content auto auto auto auto;
        grid-template-areas: 'Title' 'DealerInfoSwitcher' 'DealerInfoFragment' 'Schedule' 'Map';

        .DealerInfoSwitcher {
          width: 100%;
          display: flex;
          flex-direction: column;

          .cf-select-display-container {
            border: 1px solid #DBDBDB;

            :hover {
              border: 1px solid var(--mainColor);
            }
          }

          .dealer-info-switcher-item {
            border-radius: 12px;
            height: 40px;
            padding: 8px 16px;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            > span {
              font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            svg {
              display: none;
              min-width: 24px;
            }

            &.active, :hover {
              background: #fff;
              cursor: pointer;

              svg {
                display: block;
              }
            }
          }
        }

        @media (min-width: 768px) {
          grid-template-rows: min-content auto auto auto;
          grid-template-columns: 1fr 2fr;
          grid-template-areas: 'Title Title' 'DealerInfoSwitcher DealerInfoFragment' 'DealerInfoSwitcher Schedule' 'Map Map';
        }

        @media (min-width: 1200px) {
          grid-row-gap: 24px;
          padding: 0px max(40px, calc((100vw - 1440px) / 2)) 40px;
          grid-template-columns: minmax(220px, 1fr) 1.5fr 2.5fr;
          grid-template-areas: 'Title Title Map' 'DealerInfoSwitcher DealerInfoFragment Map' 'DealerInfoSwitcher Schedule Map' '. . Map';

          .Map > div {
            border-radius: 12px;
          }
        }
      `
    }
  }
}
