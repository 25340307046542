import AppearanceProtection from 'website/components/icons/coverages/AppearanceProtection'
import GAPInsurance from 'website/components/icons/coverages/GAPInsurance'
import ServiceContract from 'website/components/icons/coverages/ServiceContract'
import TheftInsurance from 'website/components/icons/coverages/TheftInsurance'
import TireWheel from 'website/components/icons/coverages/TireWheel'
import SmallChecked from 'website/components/icons/SmallChecked'
import TripleDots from 'website/components/icons/TripleDots'
import ChatIcon from 'website/components/icons/chat'
import SendIcon from 'website/components/icons/send-icon'
import CloseIcon from 'website/components/icons/close'
import ArrowDown from 'website/components/icons/arrowDown'

const IconSVG = (): null => null

IconSVG.ServiceContract = ServiceContract
IconSVG.AppearanceProtection = AppearanceProtection
IconSVG.GAPInsurance = GAPInsurance
IconSVG.TireWheel = TireWheel
IconSVG.TheftInsurance = TheftInsurance
IconSVG.SmallChecked = SmallChecked
IconSVG.TripleDots = TripleDots
IconSVG.Chat = ChatIcon
IconSVG.Send = SendIcon
IconSVG.Close = CloseIcon
IconSVG.ArrowDown = ArrowDown

export default IconSVG
