import { useState, useCallback, MouseEvent, Dispatch, SetStateAction, useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from '@carfluent/common'

interface UseGalleryReturn {
  isModalOpen: boolean
  selectedIdx: number
  toggleModal: () => void
  onClickOutsideImage: (e?: MouseEvent<HTMLElement>) => void
  setSelectedIdx: Dispatch<SetStateAction<number>>
}

export const useGallery = (): UseGalleryReturn => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedIdx, setSelectedIdx] = useState(0)

  const toggleModal = useCallback(() => {
    setIsModalOpen(open => !open)
  }, [])

  /**
   * Clicking on any image of the Gallery has some functionality or logic,
   * so it must not close anything.
   * But besides this IMG check we also control stopPropagation on the functional elements
   * of the GalleryItems component. This way we allow more complicated open/close behaviour
   */
  const onClickOutsideImage = useCallback((e?: MouseEvent<HTMLElement>) => {
    const isImg = (e?.target as HTMLDivElement)?.tagName === 'IMG'

    if (!isImg) {
      setIsModalOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isModalOpen) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [isModalOpen])

  return {
    isModalOpen,
    selectedIdx,
    toggleModal,
    onClickOutsideImage,
    setSelectedIdx
  }
}
