import { useEffect, useState } from 'react'
import { RouteObject } from 'react-router-dom'

import { injectHotjar } from 'website/analytics/hotjar'
import { addDynamicStyle, addRootCssVariables, addSeoMeta } from 'website/styles/utils'
import configs from '../configs'
import { generateRoutes } from 'website/routing'

interface UseAppReturn {
  routeConfigs: RouteObject[]
}

export const useApp = (): UseAppReturn => {
  const [routeConfigs, setRouteConfigs] = useState<RouteObject[]>([])
  useEffect(() => {
    injectHotjar()

    const action = async (): Promise<void> => {
      try {
        addSeoMeta(configs.title)
        addDynamicStyle(configs.routes)
        addRootCssVariables(configs.cssVariables)
        const routes = generateRoutes(configs.routes)

        setRouteConfigs(routes)
      } catch (e) {
        console.error('config load failed. Think of some fallback.')
        console.error(e)
      }
    }

    void action()
  }, [])

  return { routeConfigs }
}
