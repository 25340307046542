import { css } from '@emotion/css'

interface StyleProps {
  itemWidth: string
  transitionVelocity?: number
  styleSelected?: boolean
}

const createStyleClass = ({
  itemWidth,
  transitionVelocity = 300,
  styleSelected = true
}: StyleProps): string => {
  return css(`
    height: 100%;
    position: relative;

    & .cf-gallery-arrow {
      cursor: pointer;
      position: absolute;
      width: var(--galleryArrowSize);
      height: var(--galleryArrowSize);
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      border-radius: 50%;
      background: #fff;
      border: 0;

      &:hover {
        background: rgba(255,255,255,0.8);
      }

      &.right {
        right: 0px;
      }

      &.left {
        left: 0px;
      }

      &.disabled {
        cursor: not-allowed;
        background: rgba(255,255,255,0.8);
      }
    }

    & .cf-gallery-container {
      height: 100%;
      overflow-x: hidden;
      touch-action: pan-x;
      position: relative;

      & .cf-gallery-dots-container {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: calc(5 * var(--galeryDotSize) + 4 * var(--galeryDotGap));
        height: var(--galeryDotSize);
        z-index: 1;
        overflow: hidden;

        & .cf-gallery-dots-list {
          position: absolute;
          display: flex;
          flex-wrap: nowrap;
          left: 0px;
          transform: translateX(0);
          transition: left .3s;
          
          & .cf-gallery-dot {
            width: var(--galeryDotSize);
            min-width: var(--galeryDotSize);
            height: var(--galeryDotSize);
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);

            &.active {
              background: #fff;
            }

            &:not(:last-child) {
              margin-right: var(--galeryDotGap);
            }
          }
        }
      }

      & .cf-gallery-items-wrapper {
        display: flex;
        height: 100%;
        will-change: transform;
        user-select: none;
        transition: transform ${transitionVelocity}ms ease-in;
      }

      & .cf-gallery-item {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: ${itemWidth};
        min-width: ${itemWidth};
        position: relative;
        z-index: 10;
        color: #fff;

        &:not(:last-child) {
          padding-right: ${itemWidth === '100%' ? '0px' : 'var(--galleryThumbnailGap)'};
        }

        & img {
          ${
            styleSelected
              ? `& {
                  border: 2px solid transparent;
                }`
              : ''
          }

          border-radius: 4px;
          margin: 0 auto;
          box-sizing: border-box;

          &.cover {
            width: 100%;
            max-height: 100%;
            object-fit: cover;
          }

          &.contain {
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        },

        ${
          styleSelected
            ? `&.selected img {
                border: 2px solid rgba(201, 155, 134, 0.72);
              }`
            : ''
        }
      }
    }
`)
}

export default createStyleClass
