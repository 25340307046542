import { FC, useMemo } from 'react'
import { cn } from '@carfluent/common'
import { useGallery } from './hook'
import Header from './Header'
import GalleryItems from './GalleryItems'
import createStyleClass from './styles'

interface GalleryProps {
  images: API.ImageData[]
  media: API.VehicleMediaData | null
}

/**
 * Gallery component
 * glo3d iframe is prioritized over images for fullscreen and vice verse for simple mode
 */
const Gallery: FC<GalleryProps> = ({ images, media }) => {
  const styles = useMemo(createStyleClass, [])

  const {
    isModalOpen,
    selectedIdx,
    toggleModal,
    onClickOutsideImage,
    setSelectedIdx
  } = useGallery()

  const render3DSpinMedia = (spinEmbedUrl: string): JSX.Element => {
    return (
      <iframe
        src={spinEmbedUrl}
        className='cf-image-gallery-glo3d-iframe'
        title='spin'
        width='100%'
        height='100%'
        frameBorder='0'
        allowFullScreen
      />
    )
  }

  return (
    <>
      <div className={cn(styles.gallery, !isModalOpen ? 'visible' : '')}>
        {
          (media?.spinEmbedUrl != null) && render3DSpinMedia(media.spinEmbedUrl)
        }
        {
          (media?.spinEmbedUrl == null && images.length > 0) && (
            <>
              <GalleryItems
                imageUrls={images.map(v => v.originalUrl)}
                selected={selectedIdx}
                itemWidth='100%'
                className='cf-full-image-section'
                onClick={toggleModal}
                onSelect={setSelectedIdx}
                styleSelected={false}
                autoSwipeEnabled={!isModalOpen}
                showDots
              />
              <GalleryItems
                imageUrls={images.map(v => v.thumbnailUrl ?? v.originalUrl)}
                selected={selectedIdx}
                className='cf-thumbnails-section'
                onSelect={setSelectedIdx}
                autoSwipeEnabled={!isModalOpen}
                showArrows
              />
            </>
          )
        }
      </div>

      {
        media?.spinEmbedUrl == null && (
          <div
            id='modal-gallery-full-view'
            className={cn(styles.modalGallery, isModalOpen ? 'visible' : '')}
            onClick={onClickOutsideImage}
          >
            <div className='cf-gallery-modal-content'>
              {
                images.length > 0 && (
                  <>
                    <Header
                      onClose={toggleModal}
                      counter={`${(selectedIdx ?? 0) + 1}/${images.length}`}
                    />

                    <div className='cf-gallery-sections'>
                      <GalleryItems
                        imageUrls={images.map(v => v.originalUrl)}
                        selected={selectedIdx}
                        itemWidth='100%'
                        styleSelected={false}
                        imgBackgroundType='contain'
                        className='cf-full-image-section'
                        onSelect={setSelectedIdx}
                        isKeyboardFriendly
                        isActive={isModalOpen}
                        showArrows
                      />

                      <GalleryItems
                        imageUrls={images.map(v => v.thumbnailUrl ?? v.originalUrl)}
                        selected={selectedIdx}
                        className='cf-thumbnails-section'
                        onSelect={setSelectedIdx}
                        stopPropagation
                      />
                    </div>
                  </>
                )
              }
            </div>
          </div>
        )
      }
    </>
  )
}

export default Gallery
