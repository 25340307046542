import { SiteConfigs } from 'website/api/config.types'
import replacePlaceholders from 'website/__dealershipConfigs/utils/replacePlaceholders'
import setPropsFromTarget from 'website/__dealershipConfigs/utils/setPropsFromTarget'
import templates, { parsedTemplatesCache } from './templates'
import getHeaderConfigs from './customerAppTemplates/header'

export { getHeaderConfigs }

let configJson: SiteConfigs | null = null

export default ((): SiteConfigs => {
  if (configJson != null) {
    return configJson
  }
  /**
   * as we replace placeholders we cache parsed templates.
   * this way we reuse them in other templates.
   */
  for (const { template, assets, name } of templates) {
    const mergedAssets = setPropsFromTarget(assets, parsedTemplatesCache)
    parsedTemplatesCache[name] = replacePlaceholders(mergedAssets, template)
  }

  configJson = JSON.parse(parsedTemplatesCache.rootTemplate) as SiteConfigs
  return configJson
})()

export const getCssVars = (): Record<string, string> => configJson?.cssVariables ?? {}

export { default as globalCss } from './theme/global'
export { default as theme } from './theme'
export * from './appTypes/filters'
export * from './constants'
export * from './filters'
