import { css } from '@emotion/css'

const HEADER_HEIGHT = 124

export const BUTTON_CLASSES = css(`
  &.text-btn {
    text-decoration: underline;
  }
`)

export default css(`
  &.cf-modal-container {
    .cf-modal-content-scroll-wrapper {
      padding: 0px;
      min-height: 100vh;
      overflow: hidden;
    }

    .cf-modal-content-scroll-wrapper .cf-modal-root {
      padding: 0px;
      height: 90vh;
      min-width: min(90vw, 1536px);
      overflow: hidden;
    
      .cf-modal-title {
        padding: 0px;

        button {
          padding-top: 16px;

          svg {
            z-index: 1;
          }
        }
      }

      .cf-modal-content {
        min-height: calc(100vh - ${HEADER_HEIGHT}px);
        padding: 0px;
        margin-top: -52px;
      }
    }
  }
`)
