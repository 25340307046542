import { useRef, useState, type FC } from 'react'
import { cnx, PopoverV2, Modal, type PopoverPropsV2 } from '@carfluent/common'
import { type DealerLocationsPopoverProps, type IconTypes, SupportedComponentNames } from 'website/components/types'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import SvgIcon from 'website/components/_base/SvgIcon'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'
import formatString from 'website/utils/formatString'
import { fullAddressShort } from 'website/utils/googleMap'
import { createGoogleMapsLink, createPhoneLink } from 'website/utils/links'
import { useComponentStyles } from 'website/styles/useComponentStyles'

const COMPONENT_NAME = SupportedComponentNames.DealerLocationsPopover

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const DEFAULT_ADDRESS_CONFIG = {
  icon: 'map' as IconTypes
}

const DEFAULT_PHONE_CONFIG = {
  icon: 'phone' as IconTypes,
  format: '(###) ###-####'
}

const POPOVER_OPTIONS: PopoverPropsV2['options'] = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 24]
      }
    }
  ]
}

const MIN_POPOVER_POINT = 450

const DealerLocationsPopover: FC<DealerLocationsPopoverProps> = ({
  addressConfig = DEFAULT_ADDRESS_CONFIG,
  className,
  phoneConfig = DEFAULT_PHONE_CONFIG,
  variant
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const anchorEl = useRef<HTMLDivElement>(null)
  const componentStyles = useComponentStyles(COMPONENT_NAME, variant)
  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const { isMatchingBreakpoints } = useMediaQueryByBreakpoints([MIN_POPOVER_POINT])

  const firstDealerShip = dealerInfo.dealerships[0]

  const renderContent = (): JSX.Element => {
    return (
      <div className={componentStyles.content}>
        {
          dealerInfo.dealerships.map((dealer, index) => {
            const shortAddress = fullAddressShort({
              address: dealer.address1,
              city: dealer.city,
              state: dealer.state,
              zipCode: dealer.zipCode
            })

            return (
              <div key={index} className='dealer-info-item'>
                <h6>{dealer.dealerName}</h6>

                <a
                  className='location'
                  href={createGoogleMapsLink(shortAddress)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {
                    addressConfig?.icon != null && (
                      <SvgIcon type={addressConfig.icon} />
                    )
                  }
                  <span>{shortAddress}</span>
                </a>
                <a
                  href={createPhoneLink(dealer.phone ?? '')}
                  className='phone'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {
                    phoneConfig?.icon != null && (
                      <SvgIcon type={phoneConfig.icon} />
                    )
                  }
                  <span>
                    {formatString(dealer.phone, phoneConfig?.format)}
                  </span>
                </a>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <div className={cnx(COMPONENT_NAME, componentStyles.root, className)}>
      <div
        className={cnx('locations-header', isOpen && 'active')}
        ref={anchorEl}
      >
        <SvgIcon
          onClick={() => setIsOpen(!isOpen)}
          className={cnx('location-icon', isOpen && 'is-opened')}
          type='location'
        />

        {firstDealerShip != null && (
          <a href={`tel:${firstDealerShip?.phone ?? ''}`} className='closest-dealership-phone-number'>
            <SvgIcon type='phone' />

            <span>
              {formatString(firstDealerShip.phone, phoneConfig?.format)}
            </span>
          </a>
        )}
      </div>

      {
        isMatchingBreakpoints === true
          ? (
            <PopoverV2
              isOpen={isOpen}
              anchorEl={anchorEl}
              options={POPOVER_OPTIONS}
              onClose={() => setIsOpen(false)}
            >
              {renderContent()}
            </PopoverV2>
            )
          : (
            <Modal
              isOpen={isOpen}
              isFullscreen
              title='Locations'
              onClose={() => setIsOpen(false)}
            >
              {renderContent()}
            </Modal>
            )
      }
    </div>
  )
}

export default DealerLocationsPopover
