import { FC, ReactNode, useMemo } from 'react'
import GalleryItem, { ImageBackgroundType } from './GalleryItem'
import useGalleryItems, { UseGalleryItemsProps, ITEM_WIDTH } from './hook'
import createStyleClass from './styles'

import arrowRight from 'website/assets/arrow_right.svg'
import arrowLeft from 'website/assets/arrow_left.svg'

/**
 * sets which dot is always active counting from the start of visible dots
 */
const ACTIVE_DOT_OFFSET = 2

/**
 * makes extra buffer for dots section to support infinite swiping animation
 */
const VISIBLE_DOTS = 5

type GalleryItemsProps = Omit<UseGalleryItemsProps, 'itemsLength'> & {
  imageUrls: string[]
  className?: string
  showArrows?: boolean
  styleSelected?: boolean
  imgBackgroundType?: ImageBackgroundType
}

const GalleryItems: FC<GalleryItemsProps> = (props) => {
  const {
    imageUrls,
    selected,
    className,
    itemWidth = ITEM_WIDTH,
    imgBackgroundType = 'cover',
    showDots = false,
    showArrows = false,
    styleSelected = true
  } = props
  const itemsLength = imageUrls.length

  const rootCls = useMemo(() => createStyleClass({ itemWidth, styleSelected }), [itemWidth])

  /**
   * 1 dot is always visible as we do not allow to pass itemsLength limit
   */
  const dots = useMemo(() => new Array(itemsLength + VISIBLE_DOTS - 1).fill(true), [itemsLength])

  const {
    elRef,
    dotsElRef,
    onClick,
    onMoveLeft,
    onMoveRight,
    bind
  } = useGalleryItems({ ...props, itemsLength })

  return (
    <div className={`${rootCls} ${className ?? ''}`}>
      {showArrows && (
        <>
          <button
            onClick={onMoveLeft}
            className='cf-gallery-arrow left'
          >
            <img src={arrowLeft} alt='left' />
          </button>

          <button
            onClick={onMoveRight}
            className='cf-gallery-arrow right'
          >
            <img src={arrowRight} alt='right' />
          </button>
        </>
      )}

      <div className='cf-gallery-container' {...bind()}>
        {
          showDots && (
            <div className='cf-gallery-dots-container'>
              <div className='cf-gallery-dots-list' ref={dotsElRef}>
                {
                  dots.map((_, i): ReactNode => {
                    const isActive = (selected ?? 0) + ACTIVE_DOT_OFFSET === i

                    return (
                      <div
                        key={i}
                        className={`cf-gallery-dot ${isActive ? 'active' : ''}`}
                      />
                    )
                  })
                }
              </div>
            </div>
          )
        }
        <div className='cf-gallery-items-wrapper' ref={elRef}>
          {
            imageUrls.map((v, i) => (
              <GalleryItem
                key={v}
                url={v}
                index={i}
                isSelected={selected === i}
                imgBackgroundType={imgBackgroundType}
                onClick={onClick}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default GalleryItems
