import { RelativeRoutes, Routes } from "routing/constants";
import { getSearchParams } from './common'

export const isOnAuthPage = (pathname: string): boolean => {
  const authRoutes = [Routes.Login, Routes.Signup, Routes.Reset, Routes.ResetPassword]
  return authRoutes.some(v => pathname.includes(v))
}

/**
 * deal/YourDetails - is a special page, it should look like deal/PersonalDetails
 * but it's not a part of the wizard flow
 */
export const isYourDetailsPage = (pathname: string): boolean => {
  return pathname.includes(Routes.YourDetails)
}

export const isIndependentCoverageFlow = (pathname: string): boolean => {
  const { accessToken, dealId, rowVersion } = getSearchParams()

  return pathname.includes(Routes.IndependentCoverage) && accessToken != null && dealId != null && rowVersion != null
}

export const isIndependentCoverageSuccessPage = (pathname: string): boolean => {
  const { accessToken, dealId, rowVersion } = getSearchParams()

  return pathname.includes(RelativeRoutes.SuccessCoverageAdding) && accessToken != null && dealId != null && rowVersion != null
}

export const isDealAppOutsideFlow = (pathname: string): boolean => {
  return isOnAuthPage(pathname) ||
    isIndependentCoverageFlow(pathname) ||
    pathname.includes(Routes.PaymentsInsurance) ||
    pathname.includes(Routes.ClientArea) ||
    pathname.includes(Routes.MultipleDeals)
}
