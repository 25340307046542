import { type FC } from 'react'
import { cnx, FormInput, FormMaskedInput, Button } from '@carfluent/common'

import IconSVG from 'website/components/icons'

import type { Lead } from 'website/api/types/leads'

import { useRequestCall } from './hook'
import CLASS_NAME from './styles'

interface RequestCallProps {
  isOpened: boolean
  onClose: () => void
  onSubmit?: (lead: Lead) => void
}

const RequestCall: FC<RequestCallProps> = ({ isOpened, ...otherProps }) => {
  const {
    isClosing,
    values,
    errors,
    onChange,
    onBlur,
    onCancel,
    onSubmit,
    touched,
    isSubmitting
  } = useRequestCall(otherProps)

  return (
    <div
      onClick={onCancel}
      className={cnx(CLASS_NAME, isOpened && 'is-opened', isClosing && 'is-closing')}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='request-call-content'
      >
        <div className='request-call-header'>
          Request a call

          <span onClick={onCancel}>
            <IconSVG.Close />
          </span>
        </div>

        <div className='request-call-form-content'>
          <p className='request-call-form-title'>
            Type in your name and phone number and we’ll call you back.
          </p>

          <div className='personal-info-wrapper'>
            <FormInput
              id='firstName'
              label='First name'
              onChange={onChange}
              onBlur={onBlur}
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              className='first-name-input'
            />

            <FormInput
              id='lastName'
              label='Last name'
              onChange={onChange}
              onBlur={onBlur}
              value={values.lastName}
              error={errors.lastName}
              touched={touched.lastName}
            />
          </div>

          <FormMaskedInput
            id='phoneNumber'
            label='Phone number'
            onChange={onChange}
            onBlur={onBlur}
            value={values.phoneNumber}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            mask='phone'
          />

          <div className='empty-space' />

          <div className='action-bar'>
            <Button
              className='request-call-cancel-button'
              variant='outlined'
              onClick={onCancel}
            >
              CANCEL
            </Button>

            <Button
              className='request-call-submit-button'
              onClick={onSubmit}
              isDisabled={isSubmitting}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestCall
