import type { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { Modal, formatCurrencyDecimal } from '@carfluent/common'
import CoveragesSection from 'components/summary_sections/coverages_section'
import EstimatedFeesAndTaxesSection from 'components/summary_sections/estimated_fees_and_taxes_section'
import MiscellaneousSection from 'components/summary_sections/miscellaneous_section'
import SectionRowItem from 'components/summary_sections/shared/section_row_item'
import TradeInSection from 'components/summary_sections/trade_in_section'

import FinancingSection from './financing_section'
import useBillOfSaleDialog from './hook'

import CLASS_NAME from './styles'

const MIN_MODAL_WIDTH = 500

interface DialogProps {
  open: boolean
  onClose?: () => void
}

const BillOfSaleDialog: FC<DialogProps> = ({ open, onClose }) => {
  const {
    retailPrice,
    totalPrice,
    summary,
    coverageDetails,
    hasFinancingSection,
    feesAndTaxes
  } = useBillOfSaleDialog()

  const carDelivery = feesAndTaxes != null ? Object.entries(feesAndTaxes).filter(([key, value]) => typeof value === 'object' && key === 'carDeliverySetting') : []

  return (
    <Modal
      onClose={onClose}
      isOpen={open}
      minWidth={MIN_MODAL_WIDTH}
      title='Bill of Sale'
      className={CLASS_NAME}
    >
      <div className='bill-of-sale-content'>
        <SectionRowItem
          label='Retail price'
          value={retailPrice}
        />

        <EstimatedFeesAndTaxesSection
          feesAndTaxes={feesAndTaxes}
          salesTaxAmount={summary.salesTaxAmount}

        />

        {feesAndTaxes != null && carDelivery != null && (
          carDelivery.map(([key, value]) => (
            value?.isEnabled && (
              <SectionRowItem
                key={key}
                label={value.labelName ?? ''}
                value={formatCurrencyDecimal(value.amount ?? 0)}
              />
            )
          ))
        )}

        <MiscellaneousSection
          other1={summary.other1}
          other1Description={summary.other1Description}
          other2={summary.other2}
          other2Description={summary.other2Description}
          other3={summary.other3}
          other3Description={summary.other3Description}
        />

        <hr />

        <TradeInSection
          tradeInCredit={summary.tradeInCredit}
          tradeInPayoff={summary.tradeInPayoff}
        />

        <hr />

        <CoveragesSection
          coverageDetails={coverageDetails}
        />

        <hr />

        <SectionRowItem
          classes={{ label: 'bold-label', value: 'bold-value' }}
          label='Total purchase price'
          value={totalPrice}
        />
      </div>

      <FinancingSection
        amountFinanced={summary.amountFinanced}
        downPayment={summary.downPayment}
        hasFinancingSection={hasFinancingSection}
        interestRate={summary.interestRate}
        lender={summary.lender}
        monthlyPayment={summary.monthlyPayment}
        term={summary.term}
      />
    </Modal>
  )
}

export default observer(BillOfSaleDialog)
