import { createRef, FC, useCallback, useMemo, useState } from 'react'
import { UITools } from '@carfluent/common'
import Sidebar from 'components/layout/sidebar'

import { DrawerMenuProps, SupportedComponents } from 'website/components/types'
import List from 'website/components/List'
import useClickOutside from 'website/hooks/useClickOutside'
import MenuIcon from 'website/components/icons/menu'

/**
 * DD-NOTE: hardcoded for now. Eventually might have a string name to dynamicaly load specific image
 */
import closeIcon from 'website/assets/close.svg'

import { useComponentStyles } from 'website/styles/useComponentStyles'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'

import { createStyleClass } from './styles'

const { cn } = UITools

const defaultMinWidth = '375px'

const DrawerMenu: FC<DrawerMenuProps> = ({
  nameInLayout,
  layout,
  template,
  className,
  variant,
  componentProps,
  useDealFlowNavigation
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const stylesRoot = createStyleClass(useDealFlowNavigation === true ? '100vw' : defaultMinWidth)
  const componentStylesCls = useComponentStyles(SupportedComponents.DrawerMenu, variant)
  const templateCls = useLayoutStyles({ template, layout })

  const drawerRef = createRef<HTMLDivElement>()

  const handleClose = useCallback(() => setIsOpen(false), [])
  const toggleDrawer = useCallback(() => setIsOpen(prevVal => !prevVal), [])

  useClickOutside(drawerRef, handleClose)

  const listProps = useMemo(() => ({
    ...componentProps.List,
    items: componentProps.List.items?.map(v => ({
      ...v,
      ...(v.type === SupportedComponents.SearchBar
        ? { onSearch: toggleDrawer }
        : { onClick: toggleDrawer })
    }))
  }), [componentProps.List, toggleDrawer])

  const drawerStyle = { left: isOpen ? 0 : '-100vw' }

  return (
    <div
      ref={drawerRef}
      className={cn(nameInLayout ?? SupportedComponents.DrawerMenu, componentStylesCls.root, stylesRoot, className)}
    >
      <div className={componentStylesCls.content}>
        <div
          onClick={toggleDrawer}
          className='cf-header-drawer-icon'
        >
          <MenuIcon fill='var(--menuIconColor)' />
        </div>
        <div className={cn('cf-header-drawer-menu', templateCls)} style={drawerStyle}>
          <div className='cf-header-drawer-close-bar'>
            <img
              src={closeIcon}
              onClick={toggleDrawer}
              alt='close'
            />
          </div>
          <div className='cf-header-drawer-content'>
            {
              useDealFlowNavigation === true
                ? <Sidebar toggleDrawer={toggleDrawer} />
                : <List {...listProps} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DrawerMenu
