import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, UseFormReturn, useLoader, useSnackbar, isOk } from '@carfluent/common'

import apiProvider from 'website/api/apiProvider'
import SharedStateHook, { StoreBranches } from 'website/store'
import Routes, { TradeInSubRoutes } from 'website/routing/constants'

import type { FormValues } from './validator'
import validationRules from './validator'
import { serializeData } from './serializer'
import { DEFAULT_FORM_VALUE, ERROR_FAILED } from './constants'

const useSharedState = SharedStateHook<Store.TradeInVehicleState>(StoreBranches.TradeInVehicle)
const useSharedTradeInDetailsState = SharedStateHook<Store.TradeInDetailsVehicleState>(StoreBranches.TradeInDetailsVehicle)

interface UseTradeInUserDetailsForm extends UseFormReturn<FormValues> {
  onBack: () => void
  isLoading: boolean
}

interface UseTradeInUserDetailsFormProps {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
  }
}

export const useTradeInUserDetailsForm = ({ states }: UseTradeInUserDetailsFormProps): UseTradeInUserDetailsForm => {
  const navigate = useNavigate()
  const { showAlert } = useSnackbar()
  const [tradeInVehicle] = useSharedState(states.tradeInVehicle)
  const [tradeInDetailsVehicle] = useSharedTradeInDetailsState(states.tradeInDetailsVehicle)

  const { stopLoader, startLoader, isLoading } = useLoader()

  const submitAction = useCallback(async (values: FormValues) => {
    const data = await serializeData({
      ...tradeInVehicle,
      ...tradeInDetailsVehicle,
      ...values
    })

    if (data == null) {
      return
    }

    startLoader()
    await apiProvider.crm.createLead(data)

    navigate(Routes.Trade(TradeInSubRoutes.Offer))
  }, [startLoader, tradeInVehicle, tradeInDetailsVehicle, navigate])

  const onActionResult = useCallback((res) => {
    stopLoader()

    if (!isOk(res)) {
      showAlert(ERROR_FAILED)
    }
  }, [stopLoader, showAlert])

  const form = useForm<FormValues>({
    submitAction,
    baseValues: DEFAULT_FORM_VALUE,
    validationRules,
    onActionResult
  })

  const onBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return {
    ...form,
    onBack,
    isLoading
  }
}
