export enum Responsive {
  Mobile = 376,
  MobileMaxWidth = 480,
  WideMobileScreen = 767,
  Tablet = 1200
}

export enum NavigationStep {
  YourDetails = 'Your Details',
  TradeIn = 'Trade In',
  CashFinancing = 'Cash/Financing',
  Coverage = 'Coverage',
  CreditApplication = 'Credit Application',
  Review = 'Review',
  Documents = 'Documents',
  DocumentsUpload = 'Documents: Upload',
  DocumentsContractSigning = 'Documents: Contract Signing',
  Checkout = 'Check Out',
  PickupDelivery = 'Pickup/Delivery',
  Payment = 'Payment',
  DealCheck = 'Deal Check'
}

export enum Choise {
  NotSelected,
  No,
  Yes,
}

export const PAYMENT_METHOD_TITLE = 'Payment method'
export const DEPOSIT_TITLE = 'Deposit paid'
export const PAYMENT_METHOD = 'At dealership'
export const TOTAL_PRICE_TITLE = 'Total purchase price'
export const DOWN_PAYMENT_TITLE = 'Down payment due'
export const MONTHLY_PAYMENT_TITLE = 'Monthly payment'
export const TOTAL_PRICE_CAR_INFO_TITLE = 'Total price'
export const EMPTY_CURRENCY_VALUE = '$0'
