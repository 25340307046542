import { useEffect, useRef, useState, useCallback } from 'react'
import { useForm, type UseFormReturn,  isOk,  type OnActionResultHandler } from '@carfluent/common'

import type { Lead } from 'website/api/types/leads'

import type { FormValues } from './types'
import { validationRules } from './validator'
import { serializeData } from './serializer'

const ANIMATION_DELAY = 400

interface UseRequestCallProps {
  onClose: () => void
  onSubmit?: (lead: Lead) => void
}

type PickedProps = 'values' | 'errors' | 'touched' | 'onChange' | 'onBlur' | 'onSubmit' | 'isSubmitting'

interface UseRequestCallReturn extends Pick<UseFormReturn<FormValues>, PickedProps> {
  isClosing: boolean
  onCancel: () => void
}

const baseValues = {
  firstName: null,
  lastName: null,
  phoneNumber: null
}

export const useRequestCall = ({
  onClose: _onClose,
  onSubmit: _onSubmit
}: UseRequestCallProps): UseRequestCallReturn => {
  const [isClosing, setIsClosing] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()
  
  const onClose = () => {
    setIsClosing(true)
    
    // apply animation before closing
    timeoutRef.current = setTimeout(() => {
      _onClose();
      setIsClosing(false)
    }, ANIMATION_DELAY)
  }
  
  const submitAction = useCallback(async (values) => {
    return values
  }, [])

  const onActionResult: OnActionResultHandler<FormValues> = useCallback(async (res, resetForm) => {
    if (isOk(res)) {
      onClose()
      _onSubmit?.(serializeData(res.result))
      resetForm()
    }
  }, [onClose, _onSubmit])
  
  const {
    values,
    errors,
    onChange,
    onBlur,
    resetForm,
    onSubmit,
    touched,
    isSubmitting
  } = useForm<FormValues>({
    baseValues,
    validationRules,
    submitAction,
    onActionResult
  })
  
  const onCancel = () => {
    onClose()
    resetForm()
  }
  
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])
  
  return {
    onSubmit,
    isClosing,
    values,
    errors,
    onChange,
    onBlur,
    onCancel,
    touched,
    isSubmitting
  }
}
