import type { FC } from 'react'
import { cnx } from '@carfluent/common'
import SharedStateHook, { StoreBranches, defaultInstance } from 'website/store'
import { useLocation } from 'react-router-dom'

import DrawerMenu from 'website/components/DrawerMenu'
import ExtendedImage from 'website/components/ExtendedImage'
import Login from 'website/components/Login'
import List from 'website/components/List'
import SearchBar from 'website/components/SearchBar'

import DealerInfoFragment from 'website/components/_base/DealerInfoFragment'
import { HeaderProps, SupportedComponents } from 'website/components/types'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import CLASS_NAME from './styles'
import DealerLocationsPopover from '../_base/DealerLocationsPopover'
import isMultirooftop from 'website/utils/isMultirooftop'
import { isIndependentCoverageFlow } from 'utils/urlHelpers'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)

const isMultiDealership = isMultirooftop()

const Header: FC<HeaderProps> = ({
  nameInLayout,
  template,
  layout,
  variant,
  variantProps,
  phoneConfig,
  addressConfig,
  className,
  componentProps,
  useDealFlowNavigation = false
}) => {
  const { pathname } = useLocation()
  const componentStylesCls = useComponentStyles(SupportedComponents.Header, variant, variantProps)
  const templateCls = useLayoutStyles({ template, layout })

  const [dealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))

  const lastDealership = dealerInfo.dealerships[dealerInfo.dealerships.length - 1]

  const wrapperClass = cnx(
    nameInLayout ?? SupportedComponents.Header,
    CLASS_NAME,
    componentStylesCls.root,
    className
  )

  const isDealerLocationsShown = isMultiDealership && !useDealFlowNavigation
  const isDealerInfoFragmentShown = !isMultiDealership && !useDealFlowNavigation && lastDealership != null
  const isHiddenHeader = isIndependentCoverageFlow(pathname)

  if (isHiddenHeader) {
    return null
  }

  return (
    <div className={wrapperClass}>
      <div className={cnx(componentStylesCls.content, templateCls)}>
        <DrawerMenu
          {...componentProps.DrawerMenu}
          useDealFlowNavigation={useDealFlowNavigation}
        />

        {!useDealFlowNavigation && (
          <SearchBar
            {...componentProps.SearchBar}
            className='header-search-bar'
            shouldResetOnSearch
          />
        )}

        {
          lastDealership != null && (
            <ExtendedImage
              {...componentProps.ExtendedImage}
              src={lastDealership.logoUrl ?? componentProps.ExtendedImage.src}
            />
          )
        }
        <List {...componentProps.List} />

        {
          isDealerInfoFragmentShown && (
            <DealerInfoFragment
              phone={lastDealership.phone}
              addressData={{
                address: lastDealership.address1,
                city: lastDealership.city,
                state: lastDealership.state,
                zipCode: lastDealership.zipCode
              }}
              phoneConfig={phoneConfig}
              addressConfig={addressConfig}
            />
          )
        }
        {
          isDealerLocationsShown && (
            <DealerLocationsPopover
              className='locations-popover'
              // DD-TODO: pass proper component configs
            />
          )
        }

        <Login
          {...componentProps.Login}
          useDealFlowNavigation={useDealFlowNavigation}
        />
      </div>
    </div>
  )
}

export default Header
