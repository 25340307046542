import { FC } from 'react'
import { formatCurrencyDecimal, formatCurrency } from '@carfluent/common'

import { type CoverageFeeSettingsDto, type DefaultAmountFeeSettingDto } from 'api/types'

import CollapsedSection from '../shared/collapsed_section'
import SectionRowItem from '../shared/section_row_item'

import { SummarySectionClasses } from '../types'

interface SettingProps extends DefaultAmountFeeSettingDto {
  amount: number
}

export interface FeesAndTaxesProps extends Omit<CoverageFeeSettingsDto, 'carDeliverySetting'> {
  buyerTagSetting: SettingProps
  dealerHandlingFeeSetting: SettingProps
  dealerInventoryTaxSetting: SettingProps
  emissionsSetting: SettingProps
  inspectionFeeSetting: SettingProps
  plateTransferFeeSetting: SettingProps
  registrationFeeSetting: SettingProps
  titleFeeSetting: SettingProps
}

interface EstimatedFeesAndTaxesSectionProps {
  salesTaxAmount: number | null
  isAlwaysOpened?: boolean
  classes?: SummarySectionClasses
  feesAndTaxes: FeesAndTaxesProps | null
}

const EstimatedFeesAndTaxesSection: FC<EstimatedFeesAndTaxesSectionProps> = (props) => {
  const { isAlwaysOpened, classes, feesAndTaxes, salesTaxAmount } = props

  const filteredFeesAndTaxes = feesAndTaxes != null ? Object.entries(feesAndTaxes).filter(([key, value]) => typeof value === 'object' && key !== 'carDeliverySetting') : []
  const totalFeeAndTaxSum = Number((feesAndTaxes != null) ? filteredFeesAndTaxes.reduce((acc, [key, value]) => Number(acc) + Number(value.amount), 0) : 0) + Number(salesTaxAmount)

  return (
    <CollapsedSection
      label='Estimated fees and taxes'
      value={formatCurrency(totalFeeAndTaxSum)}
      classes={classes}
      isAlwaysOpened={isAlwaysOpened}
    >
      <SectionRowItem
        label='Sales tax'
        value={formatCurrencyDecimal(salesTaxAmount ?? 0)}
        hasLeftSpace
        classes={classes}
      />

      {feesAndTaxes != null && filteredFeesAndTaxes.map(([key, value]) => (
        value?.isEnabled && (
          <SectionRowItem
            key={key}
            label={value.labelName ?? ''}
            value={formatCurrencyDecimal(value.amount)}
            hasLeftSpace
            classes={classes}
          />
        )
      ))}

    </CollapsedSection>
  )
}

export default EstimatedFeesAndTaxesSection
