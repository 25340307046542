import { type FC, useState, useEffect, useRef } from 'react'
import { cnx } from '@carfluent/common'

import IconSVG from 'website/components/icons'

import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import apiProvider from 'website/api/apiProvider'
import { parseMessages } from 'website/components/Messenger/parser'
import { setWithExpiry, WithExpiryProps } from 'website/utils/common'
import type { MessageProps } from 'website/components/Messenger/components/Message'

import Chat from './components/Chat'

import CLASS_NAME, { CHAT_CLASS_NAME } from './styles'

const useZipCodeState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)

enum InitializationStatus {
  InProgress = 'InProgress',
  Initialized = 'Initialized',
  NotInitialized = 'NotInitialized'
}

const useBotEnabled = SharedStateHook<Store.BotEnabled>(StoreBranches.BotEnabled)

const Messenger: FC = () => {
  const [isOpened, setIsOpened] = useState(false)
  const [threadId, setThreadId] = useState<string | null>(null)
  const [zipCodeLocation] = useZipCodeState(defaultInstance(StoreBranches.ZipCodeLocation))
  const [{ isEnabled }] = useBotEnabled(defaultInstance(StoreBranches.BotEnabled))
  const initStatus = useRef(InitializationStatus.NotInitialized)
  const [isCheckingOnThread, setIsCheckingOnThread] = useState(false)
  const [defaultMessages, setDefaultMessages] = useState<MessageProps[] | null>(null)

  useEffect(() => {
    if (initStatus.current !== InitializationStatus.NotInitialized || !isOpened || threadId != null) {
      return
    }

    const runEffect = async (): Promise<void> => {
      initStatus.current = InitializationStatus.InProgress
      const { longitude, latitude } = zipCodeLocation

      if (longitude == null || latitude == null) {
        return
      }

      const { threadId } = await apiProvider.crm.createBot({
        locationLongitude: longitude,
        locationLatitude: latitude
      })

      setThreadId(threadId)
      setWithExpiry({ value: threadId })
      initStatus.current = InitializationStatus.Initialized
    }

    void runEffect()
  }, [zipCodeLocation, isOpened, threadId])

  useEffect(() => {
    const runEffect = async (): Promise<void> => {
      const now = new Date()
      let savedThreadId: string | null = null
      let expiry: number = now.getTime()

      const threadIdItem = localStorage.getItem('threadId')
      if (threadIdItem != null) {
        try {
          const parsedItem: WithExpiryProps = JSON.parse(threadIdItem)
          if (parsedItem.expiry > now.getTime()) {
            savedThreadId = parsedItem.value
            expiry = parsedItem.expiry
          } else {
            localStorage.removeItem('threadId')
          }
        } catch (error) {
          console.error('Error parsing threadId from localStorage:', error)
        }
      }

      if (savedThreadId != null && now.getTime() <= expiry) {
        try {
          const data = await apiProvider.crm.getBotChatByThreadId(savedThreadId)
          const messages = parseMessages(data.messages)
          setThreadId(savedThreadId)
          setDefaultMessages(messages)
        } catch {
          setIsCheckingOnThread(true)
        }
      }

      setIsCheckingOnThread(true)
    }

    void runEffect()
  }, [])

  if (!isEnabled || !isCheckingOnThread) {
    return null
  }

  return (
    <>
      <Chat
        threadId={threadId}
        className={cnx(CHAT_CLASS_NAME, isOpened && 'is-opened')}
        onClose={() => setIsOpened(false)}
        isOpened={isOpened}
        defaultMessages={defaultMessages}
      />

      <div className={CLASS_NAME} onClick={() => setIsOpened(true)}>
        <IconSVG.Chat />
      </div>
    </>
  )
}

export default Messenger
