import type { FC, MouseEvent } from 'react'

export type ImageBackgroundType = 'cover' | 'contain'

interface GalleryItemProps {
  url: string
  index: number
  isSelected?: boolean
  imgBackgroundType?: ImageBackgroundType
  onClick: (e: MouseEvent<HTMLElement>) => void
}

const GalleryItem: FC<GalleryItemProps> = ({
  url,
  index,
  imgBackgroundType = 'cover',
  isSelected = false,
  onClick
}) => {
  return (
    <div
      className={`cf-gallery-item ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
      data-idx={index}
    >
      <img src={url} alt='' className={imgBackgroundType} />
    </div>
  )
}

export default GalleryItem
