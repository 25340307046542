import ctaImg1 from 'website/assets/happy-coop.png'
import ctaImg2 from 'website/assets/woman-enjoying-her-financially-independence-while-buying-car.png'

const assets: Record<string, string> = {
  ctaImg1,
  ctaImg2,
  block1Text1: ' offers one of the most extensive selections of used cars in the area, with two locations and a wide variety of makes and models available. Find your dream car among our reliable sedans, spacious SUVs, or sporty convertibles, with new inventory arriving all the time.',
  block2Text1: 'Affordable Prices, Incredible Value',
  block2Text3: "DriveSimple makes car buying simple with our commitment to offering affordable prices on all of our vehicles. Our team works hard to ensure that you can find the car you need without breaking the bank, and with financing options available, we can help you get behind the wheel of your dream car in no time."
}

export default assets
