import { type FC } from 'react'
import { cnx, Modal, useModal } from '@carfluent/common'

import Button from 'website/components/Button'
import { useLayoutStyles } from 'website/styles/useLayoutStyles'
import { isCapitalOneSupported } from 'website/utils/isMultirooftop'
import { CapitalOnePQTSProps, SupportedComponents } from 'website/components/types'

import CLASS_NAME, { BUTTON_CLASSES } from './styles'

const CAPITAL_ONE_IFRAME = {
  __html: `
    <!-- Capital One: Prequal Then Shop IFrame-->
    <iframe
    id="capital-one-prequalification-embedded"
    name="capital-one-prequalification-embedded"
    sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-storage-access-by-user-activation"
    src="https://auto-digital-retail.capitalone.com/assets/prequal-modal/index.html?c1testing=false&clientToken=476446ce-fc33-4ad5-875e-6a413cb7fb34&isModal=false&isPrequalStandalone=true&productType=PQ-Then-Shop"
    style="width: 100%; height: 90vh; border: none;"
    ></iframe>
`
}

const CapitalOnePQTS: FC<CapitalOnePQTSProps> = ({
  nameInLayout = SupportedComponents.CapitalOnePQTS,
  isTextButton = false,
  endIcon,
  salePrice,
  vinNumber,
  layout,
  mainImageUrl,
  text = 'Get pre-qualified with Capital One (no impact to your credit score)'
}) => {
  const { isModalOpen, onOpenModal, onCloseModal } = useModal()
  const templateCls = useLayoutStyles({ layout })

  if (!isCapitalOneSupported()) {
    return null
  }

  return (
    <>
      <Button
        nameInLayout={nameInLayout}
        className={templateCls}
        buttonClassName={cnx(BUTTON_CLASSES, isTextButton && 'text-btn', 'capital-one-prequalification-button')}
        data-client-token='476446ce-fc33-4ad5-875e-6a413cb7fb34' // AZ-TODO: move to ENV variables
        data-sales-price={salePrice}
        data-vin={vinNumber}
        data-vehicle-image-url={mainImageUrl}
        endIcon={endIcon}
        onClick={onOpenModal}
        text={text}
        variant={isTextButton ? 'text' : undefined}
      />

      <Modal
        className={CLASS_NAME}
        isOpen={isModalOpen}
        onClose={onCloseModal}
      >
        <div dangerouslySetInnerHTML={CAPITAL_ONE_IFRAME} />
      </Modal>
    </>
  )
}

export default CapitalOnePQTS
