import { FC, ReactNode, MouseEvent, useCallback } from 'react'
import { UITools } from '@carfluent/common'
import { useNavigate } from 'react-router-dom'
import MUiButton from '@material-ui/core/Button'
import MUiInconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import { ButtonProps, SupportedComponents } from 'website/components/types'
import ComponentRoot from 'website/components/_base/ComponentRoot'
import { useComponentStyles } from 'website/styles/useComponentStyles'

import iconClose from 'website/assets/close.svg'
import iconFilters from 'website/assets/filters.svg'
import iconCapitalOne from 'website/assets/capitalOne.svg'

const { cn } = UITools

/**
 * AZ-NOTE: by convention all button variants' names should start
 * with one of MUI varaints.
 */
const BUTTON_VARIANTS = ['text', 'outlined', 'contained'] as const
type ButtonVariant = typeof BUTTON_VARIANTS[number]

const getButtonVariant = (variant: string): ButtonVariant => {
  return BUTTON_VARIANTS.find(item => variant.startsWith(item)) ?? 'contained'
}

/**
 * DD-TODO: refactor Button to use latest Button from Common Lib.
 */

const Button: FC<ButtonProps> = ({
  nameInLayout = SupportedComponents.Button,
  text,
  onClick,
  className,
  dataTestId,
  buttonClassName,
  buttonVariant,
  disabled = false,
  type = 'button',
  variant = type === 'icon' ? 'icon' : 'default',
  variantProps,
  navTo,
  navAction = 'replace',
  startIcon,
  endIcon,
  isLoading = false,
  ...rest
}) => {
  const componentStylesCls = useComponentStyles(SupportedComponents.Button, variant, variantProps)
  const startIconEl = getIconEl(startIcon)
  const endIconEl = getIconEl(endIcon)

  const navigate = useNavigate()
  const btnVariant = getButtonVariant(buttonVariant ?? variant)

  const handleClick = useCallback((evt?: MouseEvent) => {
    if (onClick != null) {
      return onClick(evt)
    }

    if (navTo != null) {
      return navigate(navTo, {
        replace: navAction === 'replace'
      })
    }
  }, [onClick, navTo, navAction])

  return (
    <ComponentRoot
      nameInLayout={nameInLayout}
      classes={{
        root: cn(componentStylesCls.root, className),
        content: cn(componentStylesCls.content)
      }}
    >
      {(type === 'icon') && (
        <MUiInconButton
          onClick={handleClick}
          data-test-id={dataTestId}
          disabled={disabled}
        >
          {startIconEl}
        </MUiInconButton>
      )}

      {(type !== 'icon') && (
        <MUiButton
          {...rest}
          className={buttonClassName}
          onClick={handleClick}
          type={type}
          variant={btnVariant}
          disableRipple={btnVariant === 'text'}
          data-test-id={dataTestId}
          disabled={disabled}
          fullWidth
          startIcon={startIconEl}
          endIcon={endIconEl}
        >
          {isLoading ? <CircularProgress color='inherit' size={24} /> : text}
        </MUiButton>
      )}
    </ComponentRoot>
  )
}

export default Button

// ========================================== //

const ICONS: Record<string, string> = {
  close: iconClose,
  filters: iconFilters,
  capitalOne: iconCapitalOne
}

export const getIconEl = (iconName?: string): ReactNode => {
  if (iconName == null) {
    return null
  }

  const iconUrl = ICONS[iconName]
  if (iconUrl == null) {
    return null
  }

  return <img src={iconUrl} aria-label={iconName} />
}
