const SUPPORTED_VIDEO_EXTS = ['mov', 'mp4']

const isVideoFile = (fileName?: string): boolean => {
  if (fileName == null) {
    return false
  }

  const ext = fileName.toLowerCase().split('.').slice(-1)[0]
  return SUPPORTED_VIDEO_EXTS.includes(ext)
}

export default isVideoFile
