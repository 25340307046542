import type { FC } from 'react'
import { cn, UI, FormMaskedInput } from '@carfluent/common'

import type { TradeInUserDetailsFormProps } from 'website/components/types'
import { SupportedComponents } from 'website/components/types'
import { ValidationLength } from 'constants/validation/constants'

import TradeInCar from 'website/components/TradeInCar'
import Button from 'website/components/Button'

import ContactYou from './components/ContactYou'

import { useTradeInUserDetailsForm } from './hook'
import { FormIds } from './hook/constants'
import CLASS_NAME from './styles'

const { FormInput } = UI

const TradeInUserDetailsForm: FC<TradeInUserDetailsFormProps> = ({
  nameInLayout = SupportedComponents.TradeInUserDetailsForm,
  states
}) => {
  const {
    onSubmit,
    onChange,
    onBlur,
    values,
    touched,
    errors,
    onBack,
    isLoading
  } = useTradeInUserDetailsForm({ states })

  return (
    <div className={cn(nameInLayout, CLASS_NAME)}>
      <TradeInCar
        states={states}
        isTitleExist={false}
        isActionBarExist={false}
      />

      <div className='cf-form-wrapper'>
        <FormInput
          id={FormIds.FirstName}
          value={values[FormIds.FirstName]}
          touched={touched[FormIds.FirstName]}
          error={errors[FormIds.FirstName]}
          onChange={onChange}
          onBlur={onBlur}
          label='First name'
          className='cf-form-input'
        />

        <FormInput
          id={FormIds.LastName}
          value={values[FormIds.LastName]}
          touched={touched[FormIds.LastName]}
          error={errors[FormIds.LastName]}
          onChange={onChange}
          onBlur={onBlur}
          label='Last name'
          className='cf-form-input'
        />

        <FormInput
          id={FormIds.Email}
          value={values[FormIds.Email]}
          touched={touched[FormIds.Email]}
          error={errors[FormIds.Email]}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Spacebar') {
              e.preventDefault()
            }
          }}
          onChange={onChange}
          label='Email'
          className='cf-form-input cf-long-input'
        />

        <FormMaskedInput
          id={FormIds.PhoneNumber}
          value={values[FormIds.PhoneNumber]}
          touched={touched[FormIds.PhoneNumber]}
          error={errors[FormIds.PhoneNumber]}
          onChange={onChange}
          onBlur={onBlur}
          label='Phone number'
          className='cf-form-input'
          mask='phone'
        />

        <FormMaskedInput
          id={FormIds.ZipCode}
          value={values[FormIds.ZipCode]}
          touched={touched[FormIds.ZipCode]}
          error={errors[FormIds.ZipCode]}
          onChange={onChange}
          mask='zipCode'
          label='Zip code'
          className='cf-form-input'
          maxLength={ValidationLength.ZIP_CODE}
        />
      </div>

      <ContactYou
        id={FormIds.ContactYou}
        value={values[FormIds.ContactYou]}
        onChange={onChange}
      />

      <div className='cf-action-bar'>
        <Button
          className='cf-continue-button'
          onClick={onSubmit}
          text='Continue'
          variant='search'
          isLoading={isLoading}
        />

        <Button
          className='cf-back-button'
          onClick={onBack}
          text='Back'
          variant='back'
        />
      </div>
    </div>
  )
}

export default TradeInUserDetailsForm
