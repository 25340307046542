import { WrapperRequest } from './wrapper.api'

const BASE_URL = `${process.env.REACT_APP_CUSTOMERSCORE ?? ''}/api/v1/`
const ACTION_URL = 'static/documents/'

class StaticDocumentsApi extends WrapperRequest {
  getTermsAndConditionsUrl (): string {
    return `${BASE_URL}${ACTION_URL}TermsAndConditions`
  }

  getPrivacyPolicyUrl (): string {
    return `${BASE_URL}${ACTION_URL}PrivacyPolicy`
  }

  getArbitrationAgreementUrl (): string {
    return `${BASE_URL}${ACTION_URL}ArbitrationAgreement`
  }

  getDisclosureUrl (): string {
    return `${BASE_URL}${ACTION_URL}Disclosure`
  }
}

export const StaticDocumentsApiProvider = new StaticDocumentsApi()
