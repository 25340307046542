import type { FC } from 'react'
import CLASS_NAME from './styles'

const LoadingScreen: FC = () => {
  return (
    <div className={CLASS_NAME}>
      <div className='gallery-block cf-skeleton' />

      <div className='brief-info-block'>
        <div className='text-block medium-block cf-skeleton' />

        <div className='text-block longest-block cf-skeleton' />

        <div className='text-block long-block cf-skeleton' />

        <div className='text-block medium-block cf-skeleton' />

        <div className='info-block cf-skeleton' />

        <div className='space-between-wrapper'>
          <div className='text-block small-block cf-skeleton' />
          <div className='text-block short-block cf-skeleton' />
        </div>

        <div className='info-block cf-skeleton' />
        
        <div className='info-block cf-skeleton' />
        
        <div className='space-between-wrapper'>
          <div className='info-block cf-skeleton' />
          <div className='info-block cf-skeleton' />
        </div>
      </div>

      <div className='main-content'>
        <div className='info-block cf-skeleton' />
        
        <div className='column-wrapper'>
          <div className='text-block strict-block cf-skeleton' />
          
          <div className='poker-column-wrapper'>
            <div className='info-block cf-skeleton' />
            <div className='info-block cf-skeleton' />
            <div className='info-block cf-skeleton' />
            <div className='info-block cf-skeleton' />
          </div>
        </div>
  
        <div className='space-between-wrapper'>
          <div className='info-block cf-skeleton' />
          <div className='info-block cf-skeleton' />
          <div className='info-block cf-skeleton' />
          <div className='info-block cf-skeleton' />
          <div className='info-block cf-skeleton' />
          <div className='info-block cf-skeleton' />
        </div>
        
        <div className='triple-column-wrapper'>
          <div className='column-wrapper'>
            <div className='text-block medium-block cf-skeleton' />
            <div className='text-block longest-block cf-skeleton'/>
            <div className='text-block long-block cf-skeleton' />
            <div className='info-block cf-skeleton' />
          </div>

          <div className='column-wrapper hide-on-mobile'>
            <div className='text-block medium-block cf-skeleton' />
            <div className='text-block longest-block cf-skeleton'/>
            <div className='text-block long-block cf-skeleton' />
            <div className='info-block cf-skeleton' />
          </div>

          <div className='column-wrapper hide-on-mobile'>
            <div className='text-block medium-block cf-skeleton' />
            <div className='text-block longest-block cf-skeleton'/>
            <div className='text-block long-block cf-skeleton' />
            <div className='info-block cf-skeleton' />
          </div>
        </div>
        
        <div className='column-wrapper'>
          <div className='text-block strict-block cf-skeleton' />
          <div className='text-block longest-block cf-skeleton'/>
          <div className='text-block long-block cf-skeleton' />
          <div className='text-block short-strict-block cf-skeleton' />
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen