import { useEffect, type FC } from 'react'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import ApiProvider from 'website/api/apiProvider'
import { setZipCodeLocationInLS } from 'website/services/storage/zipCodeLocation'
import GeoApiProvider from 'website/api/geo.api'
import { isStringEmpty } from 'website/utils/isStringEmpty'
import { useMixPanelAnalytics } from 'website/hooks/useMixPanelAnalytics'
import isMultirooftop from 'website/utils/isMultirooftop'
import CustomersCoreApiProvider from 'website/api/customersCore.api'
import apiProvider from 'website/api/apiProvider'

const useSharedState = SharedStateHook<Store.Dealership>(StoreBranches.Dealership)
const useSettingsState = SharedStateHook<Store.Settings>(StoreBranches.Settings)
const useZipCodeLocationState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)
const useBotEnabled = SharedStateHook<Store.BotEnabled>(StoreBranches.BotEnabled)

const _isMultirooftop= isMultirooftop()

const AppStart: FC = () => {
  const [, setDealerInfo] = useSharedState(defaultInstance(StoreBranches.Dealership))
  const [, setSettings] = useSettingsState(defaultInstance(StoreBranches.Settings))
  const [botEnabled, setBotEnabled] = useBotEnabled(defaultInstance(StoreBranches.BotEnabled))
  const [{ longitude, latitude, zipCode }, setZipCodeLocationState] = useZipCodeLocationState(
    defaultInstance(StoreBranches.ZipCodeLocation), setZipCodeLocationInLS
  )

  useMixPanelAnalytics()

  useEffect(() => {
    if (!isStringEmpty(zipCode)) {
      return
    }

    const runEffect = async (): Promise<void> => {
      const res = await GeoApiProvider.getGeoByIp()

      setZipCodeLocationState((prev) => ({
        ...prev,
        ...res,
        lastUpdateTs: Date.now()
      }))
    }

    void runEffect()
  }, [setZipCodeLocationState, zipCode])

  useEffect(() => {
    const getInfo = async (): Promise<void> => {
      if (latitude == null || longitude == null) {
        return
      }

      const info = await ApiProvider.dealer.getInfo({
        locationLatitude: latitude,
        locationLongitude: longitude
      })

      setDealerInfo((state) => ({
        ...state,
        dealerships: info,
        lastUpdateTs: Date.now()
      }))
    }

    void getInfo()
  }, [setDealerInfo, longitude, latitude])
  
  useEffect(() => {
    const runEffect = async () => {
      const headquartersId = process.env.REACT_APP_HEADQUARTERS_ID
      
      if (_isMultirooftop) {
        if (headquartersId == null || longitude == null || latitude == null) {
          return
        }
        
        const { id } = await CustomersCoreApiProvider.getClosestDealerId({
          locationLatitude: latitude,
          locationLongitude: longitude,
          headquartersId: Number(headquartersId)
        })
  
        if (id != null) {
          localStorage.setItem('closestDealerId', id.toString())
        }
      }

      const {
        botEnabled: isEnabled
      } = await apiProvider.crm.getBotSettings()
      
      setBotEnabled({
        ...botEnabled,
        isEnabled
      })
    }

    void runEffect()
  }, [longitude, latitude, setBotEnabled])

  useEffect(() => {
    const getSettings = async (): Promise<void> => {
      const settings = await ApiProvider.dealer.getSettings()

      setSettings((state) => ({
        ...state,
        settings
      }))
    }

    void getSettings()
  }, [setSettings])

  return null
}

export default AppStart
