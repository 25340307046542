import { ValidationLength } from 'constants/validation/constants'
import subYears from 'date-fns/subYears'

export const MIN_YEARS_OLD = 18
export const MAX_YEARS_OLD = 100

export const BIRTHDATE_RANGE_START = subYears(new Date(), MIN_YEARS_OLD)
export const BIRTHDATE_RANGE_END = subYears(new Date(), MAX_YEARS_OLD)

export const MAX_AMOUNT = 999999999
export const MIN_AMOUNT = 1
export const MIN_SSN_AMOUNT = 4

export enum Errors {
  Empty = 'Field is required',
  NotSymbols = 'Should not contain symbols',
  LongName = 'Name seems to be too long',
  Email = 'Invalid email',
  LongEmail = 'Email seems to be too long',
  ShortEmail = 'Email seems to be too short',
  InvalidDate = 'Invalid date',
  OnlyLatin = 'Should contain only latin characters',
  InvalidAddress = 'Address should have state, city, address and zip code',
  InvalidCondition = 'Please select one of the options',
  ZipCode = 'Should contain 5 symbols',
  SsnNumber = 'Should contain 4 symbols'
}

export const ErrorsMsg = {
  minAmount: `Cannot be less than ${MIN_AMOUNT}`,
  maxAmount: `Cannot be bigger than ${MAX_AMOUNT}`,
  minName: `Should contain at least ${ValidationLength.NAME_MIN} letters`,
  phoneNumber: `Should contain ${ValidationLength.PHONE_NUMBER} numbers`,
  birthdayMax: `You need to be ${MIN_YEARS_OLD} years or older`,
  birthdayMin: `You need to be ${MAX_YEARS_OLD} years or younger`
}
