import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UITools } from '@carfluent/common'
import Skeleton from '@material-ui/lab/Skeleton'

import { LazyImageProps, SupportedComponents } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import useWithIntersectionObserver from 'website/hooks/useWithIntersectionObserver'

const { cn } = UITools

type ImageLoading = 'idle' | 'pending' | 'error' | 'success'

const LazyImage: FC<LazyImageProps> = ({
  nameInLayout = SupportedComponents.LazyImage,
  src,
  alt,
  lazyLoad = true,
  isPlaceholder = false
}) => {
  const componentCls = useComponentStyles(SupportedComponents.LazyImage)
  const [loading, setLoading] = useState<ImageLoading>('idle')
  const loadingRef = useRef(false)
  const imgRef = useRef<HTMLImageElement | null>(null)

  const mountTs = useMemo(() => Date.now().toString(), [])

  const loadImage = useCallback(() => {
    if ((src != null) && (imgRef.current == null) && !loadingRef.current) {
      loadingRef.current = true
      setLoading('pending')

      const img = new Image()
      img.src = src
      img.alt = alt ?? 'image'

      img.onload = () => {
        imgRef.current = img
        loadingRef.current = false
        setLoading('success')
      }

      img.onerror = () => {
        setLoading('error')
        loadingRef.current = false
      }
    }
  }, [src, alt])

  useEffect(() => {
    /**
     * if lazyLoad is false we must load images here because interserctionObserver will not trigger loadImage
     */
    if (!lazyLoad) {
      void loadImage()
    }
  }, [loadImage, lazyLoad])

  const { elRef } = useWithIntersectionObserver<HTMLDivElement>(mountTs, loadImage, lazyLoad)

  const renderContent = (): ReactNode => {
    if (loading === 'error') {
      return <div>Image loading error</div>
    }

    if (loading === 'success') {
      return null
    }

    if (loading === 'pending') {
      return <Skeleton component='div' />
    }

    return null
  }

  return (
    <div
      ref={elRef}
      className={cn(nameInLayout, componentCls.root, isPlaceholder ? 'Placeholder' : '')}
      data-key={mountTs}
      {...(loading === 'success'
        ? { dangerouslySetInnerHTML: { __html: imgRef.current?.outerHTML ?? '' } }
        : {}
      )}
    >
      {renderContent()}
    </div>
  )
}

export default LazyImage
