/**
 * WARNING: it is important each template ends with 'Template' in the name
 */
export enum AvailableTemplates {
  HeaderTemplate = 'headerTemplate',
  FooterTemplate = 'footerTemplate',
  AboutUsPromosForMainPageTemplate = 'aboutUsPromosForMainPageTemplate',
  AboutUsPromosForAboutUsPageTemplate = 'aboutUsPromosForAboutUsPageTemplate',
  FinancingPageTemplate = 'financingPageTemplate',
  TradePageTemplate = 'tradePageTemplate',
  AboutUsPageTemplate = 'aboutUsPageTemplate',
  DealerInfoTemplate = 'dealerInfoTemplate',
  IncludedWithCarAdsTemplate = 'includedWithCarAdsTemplate',
  RootTemplate = 'rootTemplate',
  PrequalifiedFormTemplate = 'prequalifiedFormTemplate',
  PrequalifiedFormSuccessTemplate = 'prequalifiedFormSuccessTemplate',
  PrequalifiedFormErrorTemplate = 'prequalifiedFormErrorTemplate',
  InventoryPageTemplate = 'inventoryPageTemplate',
  VehicleDetailsTemplate = 'vehicleDetailsTemplate',
  PrequalifiedFormSSNIncomeTemplate = 'prequalifiedFormSSNIncomeTemplate',
  UnsubscribePageTemplate = 'unsubscribePageTemplate',
  UnsubscribedPageTemplate = 'unsubscribedPageTemplate',
}

export interface JsonTemplate {
  name: AvailableTemplates
  template: object
  assets: Record<string, string>
}

export interface JsonTemplateData {
  name: AvailableTemplates
  template: string
  assets: Record<string, string>
}
