import { WrapperRequest } from './wrapper.api'

class FilesApi extends WrapperRequest {
  url: string = `${process.env.REACT_APP_FILES ?? ''}/api/v1`

  getOriginalFileUrl (slug: string, id: string | number): string {
    return `${this.url}/Download/${slug}/${id}`
  }

  getVehicleVideoUrl (id: string | number): string {
    return this.getOriginalFileUrl('vehicle-videos', id)
  }

  getVehicleVideoThumbnailUrl (id: string | number): string {
    return this.getOriginalFileUrl('vehicle-videos/thumbnail', id)
  }
}

const FilesApiProvider = new FilesApi()

export default FilesApiProvider
