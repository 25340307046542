import { type Dispatch, type SetStateAction, useCallback, useMemo, useState } from 'react'
import { UI, useForm, UseFormReturn, useLoader, useSnackbar, isOk } from '@carfluent/common'

import apiProvider from 'website/api/apiProvider'
import { ServiceType } from 'website/api/types'

import { createFormValidation } from './validator'
import { DEFAULT_FORM_VALUES, FormValues, serializeLeadData } from './serializer'

enum ErrorsMessages {
  Success = 'Request has been sent',
  Failed = 'Failed to send request. Please try again later.'
}

const SNACKBAR_KEY = 1

const SUCCESS_RESULT_ARGS: [string, number, UI.SnackbarOptions] = [
  ErrorsMessages.Success,
  SNACKBAR_KEY,
  { variant: 'success' }
]

export interface UseVehicleRequestServiceProps {
  dealerId: string | number
  serviceType: ServiceType
  vehicleInfo?: string
  onSubmit?: () => void
  onClose: () => void
  vehicleId?: number
}

export type PickedFormReturnValues = Pick<UseFormReturn<FormValues>, 'touched' | 'values' | 'errors'| 'onBlur' | 'onChange' | 'onSubmit'>

export interface UseVehicleRequestServiceReturn extends PickedFormReturnValues {
  isLoading: boolean
  isDatePickerOpen: boolean
  isThankDialogOpen: boolean
  onDateChange: (evt: unknown, value: string | null, id: string) => Promise<void>
  onDatePickerBlur: () => void
  onDatePickerClose: () => void
  onDatePickerClick: () => void
  onSubmit: FormSubmit
  onClose: () => void
  setThankDialogOpen: Dispatch<SetStateAction<boolean>>
}

const useVehicleRequestService = ({
  dealerId,
  serviceType,
  onSubmit: _onSubmit,
  onClose: _onClose,
  vehicleId
}: UseVehicleRequestServiceProps): UseVehicleRequestServiceReturn => {
  const [isDatePickerOpen, setDatePickerOpen] = useState(false)
  const [isThankDialogOpen, setThankDialogOpen] = useState(false)
  const { showAlert } = useSnackbar()
  const {
    startLoader,
    stopLoader,
    isLoading
  } = useLoader()

  const submitAction = useCallback(async (values) => {
    startLoader()

    await apiProvider.crm.createLead(serializeLeadData({
      serviceType, vehicleId, ...values, dealerId
    }))
  }, [
    serviceType,
    startLoader,
    vehicleId
  ])

  const onActionResult = useCallback((res, resetForm) => {
    stopLoader()

    if (isOk(res)) {
      showAlert(...SUCCESS_RESULT_ARGS)
      resetForm(DEFAULT_FORM_VALUES)
      _onSubmit?.()
      setThankDialogOpen(true)
    } else {
      showAlert(ErrorsMessages.Failed)
    }
  }, [showAlert, _onSubmit, stopLoader])

  const validationRules = useMemo(() => {
    const isServiceType = serviceType === ServiceType.TestDrive

    return createFormValidation(isServiceType)
  }, [serviceType])

  const {
    values,
    touched,
    errors,
    onSubmit,
    onChange,
    setFieldTouched,
    setFieldValue,
    onBlur: _onBlur,
    validateForm,
    resetForm
  } = useForm<FormValues>({
    baseValues: DEFAULT_FORM_VALUES,
    validationRules,
    submitAction,
    onActionResult
  })

  const onClose = useCallback(() => {
    resetForm(DEFAULT_FORM_VALUES)
    _onClose()
  }, [_onClose, resetForm])

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onBlur = useCallback((e) => {
    _onBlur(e)
    validateForm()
  }, [validateForm, _onBlur])

  const onDateChange = useCallback(async (evt: unknown, value: string | null, id: string) => {
    setFieldValue(id, evt)
    setFieldTouched(id, false)
  }, [setFieldValue, setFieldTouched])

  const onDatePickerClick = useCallback(() => {
    setDatePickerOpen(true)
  }, [])

  const onDatePickerClose = useCallback(() => {
    setDatePickerOpen(false)
    setFieldTouched('dateTime', true)
  }, [setFieldTouched])

  const onDatePickerBlur = useCallback(() => {
    setFieldTouched('dateTime', true)
    validateForm()
  }, [setFieldTouched, validateForm])

  // ========================================== //

  return {
    values,
    touched,
    errors,
    onSubmit,
    onBlur,
    onChange,
    isLoading,
    isDatePickerOpen,
    isThankDialogOpen,
    onDateChange,
    onDatePickerBlur,
    onDatePickerClose,
    onDatePickerClick,
    onClose,
    setThankDialogOpen
  }
}

export default useVehicleRequestService
