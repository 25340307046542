import {
  FILTER_NAMES,
  FILTER_TYPES,
  RANGE_FILTER_KEYS,
  SEARCH_FILTER_KEY,
  LIST_FILTER_KEYS,
  BODY_STYLES,
  SORT_ORDERS
} from '../constants'

export interface SortOption {
  sortField: string
  sortOrder: typeof SORT_ORDERS[number]
  title: string
}

export type SortOrder = typeof SORT_ORDERS[number]

export interface VehiclesSorting {
  sortOrder: SortOrder
  sortField: string
}

export interface VehicleSortingItem extends VehiclesSorting {
  title: string
}

export interface RangeFilterConfig {
  min: number
  max: number
  step: number
}

export type AllFilters = Map<FilterName, GeneratedFilter>

/**
 * Api filter type mappings
 */
export type FilterName = typeof FILTER_NAMES[number]
export type FilterRenderType = typeof FILTER_TYPES[number]
export type FilterRangeKey = typeof RANGE_FILTER_KEYS[number]
export type FilterListKey = typeof LIST_FILTER_KEYS[number]
export type FilterBodyStyleKey = typeof BODY_STYLES[number]
export type FilterSearchKey = typeof SEARCH_FILTER_KEY

export type ActiveFilters = Partial<{
  [key in FilterName | 'text']: key extends FilterRangeKey
    ? API.RangeFilter
    : key extends FilterSearchKey
      ? string
      : API.ListFilter
}>

/**
 * App domain filter type mappings
 */

export type FilterConfig =
  | API.ListFilter
  | API.RangeFilter

export interface GeneratedFilter<
TConfig extends FilterConfig = FilterConfig,
> {
  id: FilterName
  type: FilterRenderType
  name: string
  config: TConfig
  applied?: TConfig
  isDisabled?: (appliedFilters?: ActiveFilters) => boolean
  isCollapsible?: boolean
  startAdornment?: string
}

export interface GeneratedFilterProps<
  TConfig extends FilterConfig = FilterConfig,
> extends GeneratedFilter<TConfig> {
  onChange: (id: string, value: string, type: FilterRenderType, isRemoveFromList?: boolean) => void

  disabled?: boolean

  /**
   * used for dropdown filters
   */
  disableSelectAll?: boolean
  isMultiselect?: boolean
  listSeparator?: string
  placeholder?: string

  /**
   * used for tile filters
   */
  nameToImgMap?: Map<string, string>

  /**
   * used for list filters
   */
  renderOptionName?: (name: string) => string

  /**
   * used for range filters
   */
  min?: number
  max?: number
  startAdornment?: string
  step?: number
  thousandSeparator?: ',' | ' ' | null
}

export const isRangeFilterKey = (key: string): key is FilterRangeKey => {
  return RANGE_FILTER_KEYS.includes(key as FilterRangeKey)
}

export const isListFilterKey = (key: string): key is FilterListKey => {
  return LIST_FILTER_KEYS.includes(key as FilterListKey)
}

export const isGeneratedListFilter = (
  props: GeneratedFilter<FilterConfig>
): props is GeneratedFilter<API.ListFilter> => {
  return 'items' in props.config && (props.applied == null || 'items' in props.applied)
}

export const isGeneratedRangeFilter = (
  props: GeneratedFilter<FilterConfig>
): props is GeneratedFilter<API.RangeFilter> => {
  return 'from' in props.config && 'to' in props.config
}
