import type { JsonTemplate, JsonTemplateData } from './types'
import { AvailableTemplates } from './types'
import headerTemplate from './configFiles/header'
import footerTemplate from './configFiles/footer'
import financingPageTemplate from './configFiles/financingPage'
import tradePageTemplate from './configFiles/tradePage'
import aboutUsPageTemplate from './configFiles/aboutUsPage'
import dealerInfoTemplate from './configFiles/dealerInfo'
import inventoryPageTemplate from './configFiles/inventoryPage'
import prequalifiedFormTemplate from './configFiles/prequalifiedForm'
import prequalifiedFormSuccessTemplate from './configFiles/prequalifiedFormSuccessPage'
import prequalifiedFormErrorTemplate from './configFiles/prequalifiedFormErrorPage'
import prequalifiedFormSSNIncomeTemplate from './configFiles/prequalifiedFormSSNIncome'
import {
  aboutUsPromosForAboutUsPage,
  aboutUsPromosForMainPage
} from './configFiles/aboutUsPromos'
import includedWithCarAdsTemplate from './configFiles/includedWithCarAds'
import vehicleDetailsTemplate from './configFiles/vehicleDetails'
import rootTemplate from './configFiles/root'
import unsubscribePageTemplate from './configFiles/unsubscribePage'
import unsubscribedPageTemplate from './configFiles/unsubscribedPage'

const prepareTemplate = (data: JsonTemplate): JsonTemplateData => {
  return {
    ...data,
    template: JSON.stringify(data.template)
  }
}

/**
 * order is important.
 * Please start from those json configs which does not have any other json references.
 */
const templates: JsonTemplateData[] = [
  /** components */
  prepareTemplate(headerTemplate),
  prepareTemplate(footerTemplate),
  prepareTemplate(dealerInfoTemplate),
  prepareTemplate(aboutUsPromosForMainPage),
  prepareTemplate(aboutUsPromosForAboutUsPage),
  prepareTemplate(includedWithCarAdsTemplate),

  /** pages */
  prepareTemplate(inventoryPageTemplate),
  prepareTemplate(prequalifiedFormTemplate),
  prepareTemplate(prequalifiedFormSSNIncomeTemplate),
  prepareTemplate(prequalifiedFormSuccessTemplate),
  prepareTemplate(prequalifiedFormErrorTemplate),
  prepareTemplate(financingPageTemplate),
  prepareTemplate(tradePageTemplate),
  prepareTemplate(aboutUsPageTemplate),
  prepareTemplate(vehicleDetailsTemplate),
  prepareTemplate(unsubscribePageTemplate),
  prepareTemplate(unsubscribedPageTemplate),

  /** root (always must go last) */
  prepareTemplate(rootTemplate)
]

/**
 * used as a global and sharable cache map for parsed templates
 */
export const parsedTemplatesCache: Record<AvailableTemplates, string> = {
  headerTemplate: '',
  footerTemplate: '',
  aboutUsPromosForMainPageTemplate: '',
  aboutUsPromosForAboutUsPageTemplate: '',
  financingPageTemplate: '',
  prequalifiedFormTemplate: '',
  prequalifiedFormSuccessTemplate: '',
  prequalifiedFormErrorTemplate: '',
  prequalifiedFormSSNIncomeTemplate: '',
  includedWithCarAdsTemplate: '',
  tradePageTemplate: '',
  aboutUsPageTemplate: '',
  dealerInfoTemplate: '',
  inventoryPageTemplate: '',
  vehicleDetailsTemplate: '',
  unsubscribePageTemplate: '',
  unsubscribedPageTemplate: '',
  rootTemplate: ''
}

export default templates
