import { FC, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { SnackbarProvider } from 'notistack'
import { UI } from '@carfluent/common'
import { CircularProgress } from '@material-ui/core'
import Routing from 'website/routing'
import RouteChangeTracker from 'website/components/_base/RouteChangeTracker'
import LoginFlowModalsTracker from 'components/login/LoginFlowModalsTracker'
import AppStart from 'website/components/_base/AppStart'

import { useApp } from 'website/hooks/useApp'
import { globalCss } from 'website/configs'
import './index.css'

const { GlobalStyles } = UI

const App: FC = () => {
  const { routeConfigs } = useApp()

  const renderContent = useCallback(() => {
    if (routeConfigs.length === 0) {
      return (
        <div className='cf-global-loader'>
          <CircularProgress />
        </div>
      )
    }

    return (
      <RecoilRoot>
        <SnackbarProvider>
          <AppStart />
          <RouteChangeTracker />
          <LoginFlowModalsTracker />
          <GlobalStyles styles={globalCss} />
          <Routing routeConfigs={routeConfigs} />
        </SnackbarProvider>
      </RecoilRoot>
    )
  }, [routeConfigs])

  return (
    <BrowserRouter>
      {renderContent()}
    </BrowserRouter>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
