import { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import {
  WorkflowStepName,
  type CoverageFeeSettingsDto,
  type DefaultAmountFeeSettingDto
} from 'api/types'
import type { CoverageSummary } from 'api/types/coverage.types'
import { type Summary } from 'api/types/summary.types'
import WizardCTX from 'components/wizard/wizard.context'
import WorkflowCTX from 'store/workflow'
import SummaryCTX from 'store/summary'
import { DEFAULT_SUMMARY } from 'store/mock'
import { formatCurrency } from 'utils/format_number'
import { isYourDetailsPage } from 'utils/urlHelpers'
import { getCoverageSummary } from 'utils/coverage'
import { mergeTwoFeesInfo } from 'utils/mergeTwoFeesInfo'

interface UseBillOfSaleDialogReturn {
  retailPrice: string
  totalPrice: string
  transportationCost: string
  summary: Summary
  coverageDetails: CoverageSummary[]
  hasFinancingSection: boolean
  feesAndTaxes: FeesAndTaxesProps | null
}

interface SettingProps extends DefaultAmountFeeSettingDto {
  amount: number
}

export interface FeesAndTaxesProps extends CoverageFeeSettingsDto {
  buyerTagSetting: SettingProps
  carDeliverySetting: SettingProps
  dealerHandlingFeeSetting: SettingProps
  dealerInventoryTaxSetting: SettingProps
  emissionsSetting: SettingProps
  inspectionFeeSetting: SettingProps
  plateTransferFeeSetting: SettingProps
  registrationFeeSetting: SettingProps
  titleFeeSetting: SettingProps
}

const useBillOfSaleDialog = (): UseBillOfSaleDialogReturn => {
  const location = useLocation()
  const { salePrice = '-' } = isYourDetailsPage(location.pathname) ? location.state : {}

  const { workflow } = useContext(WorkflowCTX)
  const { summaryDetails } = useContext(SummaryCTX)

  const shouldUseEmptyValues = isYourDetailsPage(location.pathname)

  const summary = useMemo(() =>
    shouldUseEmptyValues ? DEFAULT_SUMMARY : summaryDetails,
  [summaryDetails, shouldUseEmptyValues])

  const coverageDetails = getCoverageSummary(summary)
  const hasFinancingSection = Boolean(summary.monthlyPayment)
  const retailPrice: string = shouldUseEmptyValues ? formatCurrency(salePrice ?? 0) : formatCurrency(summary?.purchasePrice ?? 0)
  const totalPrice: string = shouldUseEmptyValues ? formatCurrency(salePrice ?? 0) : formatCurrency(summary?.totalPrice ?? 0)
  const transportationCost = formatCurrency(summary.transportationCost ?? 0, '$0')

  const feesAndTaxes = useMemo(() => {
    if (workflow?.coverageFeeSettings == null) return null

    return mergeTwoFeesInfo(workflow?.coverageFeeSettings, workflow.dealFees, Number(workflow?.financeInfo?.dealerInventoryTaxAmount))
  }, [workflow])

  return {
    retailPrice,
    totalPrice,
    transportationCost,
    summary,
    coverageDetails: coverageDetails.filter(x => Boolean(x.dealerRetailPrice)),
    hasFinancingSection,
    feesAndTaxes: feesAndTaxes ?? null
  }
}

export default useBillOfSaleDialog
