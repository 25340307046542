import noCarsIcon from 'website/assets/no-cars.svg'
import notFound from 'assets/404.svg'
import successFinancingFormImage from 'website/assets/success_financing_form.svg'
import financingAlarm from 'website/assets/financing_alarm.svg'
import financingCash from 'website/assets/financing_cash.svg'

const assets: Record<string, string> = {
  noCarsIcon,
  notFound,
  successFinancingFormImage,
  financingAlarm,
  financingCash,

  /** nested templates */
  /** -- they are replaced during parsing if proper order is fulfilled -- */

  headerTemplate: '',
  footerTemplate: '',
  aboutUsPromosForMainPageTemplate: '',
  includedWithCarAdsTemplate: '',
  financingPageTemplate: '',
  prequalifiedFormSuccessTemplate: '',
  prequalifiedFormErrorTemplate: '',
  prequalifiedFormSSNIncomeTemplate: '',
  prequalifiedFormTemplate: '',
  tradePageTemplate: '',
  aboutUsPageTemplate: '',
  dealerInfoTemplate: '',
  inventoryPageTemplate: '',
  vehicleDetailsTemplate: '',
  unsubscribePageTemplate: '',
  unsubscribedPageTemplate: ''
}

export default assets
