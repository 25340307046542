import { type KeyboardEvent, useCallback, useEffect, useRef, useState, type RefObject } from 'react'

import apiProvider from 'website/api/apiProvider'

import type { MessageProps } from 'website/components/Messenger/components/Message'
import type { Lead } from 'website/api/types/leads'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'

export enum KeyCode {
  Enter = 'Enter',
}

interface UseChatProps {
  threadId: string | null
  isOpened: boolean
  defaultMessages: MessageProps[] | null
}

interface UseChatPropsReturn {
  setIsRequestCallOpened: (isOpen: boolean) => void
  isRequestCallOpened: boolean
  isSending: boolean
  isFirstTyping: boolean
  messages: MessageProps[]
  value: string
  setValue: (value: string) => void
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  onSend: () => void
  onSubmitRequestCall: (lead: Lead) => void
  isDisabledSend: boolean
  chatContentRef: RefObject<HTMLDivElement>
  chatContainerRef: RefObject<HTMLDivElement>
}

let customMessageId = -1

const getDefaultMessage = (): MessageProps => ({
  id: --customMessageId,
  isReceived: true,
  isSending: false,
  message: 'Hello!\n How can I assist you?'
})

const getCallRequestedMessage = (): MessageProps => ({
  id: --customMessageId,
  isReceived: true,
  isSending: false,
  message: 'We received your call request. One of our support team members will contact you shortly.'
})

const useZipCodeState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)

const FIRST_TYPING_DELAY = 1000

export const useChat = ({
  threadId,
  isOpened,
  defaultMessages
}: UseChatProps): UseChatPropsReturn => {
  const [value, setValue] = useState('')
  const [isRequestCallOpened, setIsRequestCallOpened] = useState(false)
  const [messages, setMessages] = useState<MessageProps[]>([])
  const [isFirstTyping, setIsFirstTyping] = useState(true)
  const [zipCodeLocation] = useZipCodeState(defaultInstance(StoreBranches.ZipCodeLocation))
  const [isSending, setIsSending] = useState(false)
  const [leadDataForSubmit, setLeadDataForSubmit] = useState<Lead | null>(null)
  const firstOpenedRef = useRef(false)
  const sendingMessageRef = useRef(false)
  const pendingMessageRef = useRef<string | null>(null)

  const isDisabledSend = value.trim() === ''

  const messagesRef = useRef<MessageProps[]>(messages)
  const chatContentRef = useRef<HTMLDivElement>(null)
  const chatContainerRef = useRef<HTMLDivElement>(null)

  const onSetMessage = useCallback((message) => {
    const next = [message, ...messagesRef.current]
    messagesRef.current = next
    setMessages(next)
  }, [])

  const onSendMessageToBot = useCallback(async (message: string) => {
    if (threadId == null) {
      return
    }

    const { content } = await apiProvider.crm.createBotMessage(threadId, message)

    onSetMessage({
      id: --customMessageId,
      message: content,
      isSending: false,
      isReceived: true
    })

    setIsSending(false)
    sendingMessageRef.current = false
  }, [onSetMessage, threadId])

  const onSubmitRequestCall = useCallback((lead: Lead) => {
    onSetMessage(getCallRequestedMessage())

    setLeadDataForSubmit({
      locationLatitude: zipCodeLocation.latitude,
      locationLongitude: zipCodeLocation.longitude,
      ...lead
    })
  }, [zipCodeLocation])

  const onSend = (): void => {
    if (isDisabledSend) {
      return
    }

    onSetMessage({
      id: --customMessageId,
      message: value,
      isSending: false
    })

    setIsSending(true)

    if (threadId == null && pendingMessageRef.current == null) {
      pendingMessageRef.current = value
    }

    if (!sendingMessageRef.current) {
      sendingMessageRef.current = true
      void onSendMessageToBot(value)
    }

    setValue('')
  }

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    const isEnterKey = e.key === KeyCode.Enter

    if (isEnterKey) {
      e.preventDefault()

      const isBrakeLine = e.metaKey || e.altKey || e.shiftKey || e.ctrlKey

      if (isBrakeLine) {
        setValue(value + '\n')
      } else {
        onSend()
      }
    }
  }

  useEffect(() => {
    if (!isOpened || firstOpenedRef.current) {
      return
    }

    firstOpenedRef.current = true
    setIsFirstTyping(true)

    const timeout = setTimeout(() => {
      onSetMessage(getDefaultMessage())
      setIsFirstTyping(false)
    }, FIRST_TYPING_DELAY)

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpened])

  useEffect(() => {
    if (threadId == null || leadDataForSubmit == null) {
      return
    }

    const runEffect = async (): Promise<void> => {
      await apiProvider.crm.createLead({ ...leadDataForSubmit, threadId })
      setLeadDataForSubmit(null)
    }

    void runEffect()
  }, [threadId, leadDataForSubmit])

  useEffect(() => {
    if (pendingMessageRef.current == null || threadId == null) {
      return
    }

    sendingMessageRef.current = true
    void onSendMessageToBot(pendingMessageRef.current)
    pendingMessageRef.current = null
  }, [onSendMessageToBot, threadId])

  useEffect(() => {
    if (defaultMessages == null) {
      return
    }

    firstOpenedRef.current = true
    setIsFirstTyping(false)
    messagesRef.current = [getDefaultMessage(), ...defaultMessages].reverse()
    setMessages(messagesRef.current)
  }, [defaultMessages])

  useEffect(() => {
    const chatContainer = chatContainerRef.current
    const chatContent = chatContentRef.current

    if ((chatContainer == null) || (chatContent == null)) return

    const handleScrollContent = (e: WheelEvent): void => {
      e.preventDefault()

      if (chatContent != null) {
        chatContent.scrollTop += e.deltaY
      }
    }

    const handleScrollContainer = (e: WheelEvent): void => {
      e.preventDefault()
    }

    chatContent.addEventListener('wheel', handleScrollContent, { passive: false })
    chatContainer.addEventListener('wheel', handleScrollContainer, { passive: false })

    return () => {
      chatContent.removeEventListener('wheel', handleScrollContent)
      chatContainer.removeEventListener('wheel', handleScrollContainer)
    }
  }, [])

  return {
    setIsRequestCallOpened,
    isRequestCallOpened,
    isSending,
    isFirstTyping,
    messages,
    value,
    setValue,
    onKeyDown,
    onSend,
    onSubmitRequestCall,
    isDisabledSend,
    chatContentRef,
    chatContainerRef
  }
}
