import { createContext } from 'react'
import { action, computed, makeObservable, observable } from 'mobx'

import {
  type WorkflowStep,
  type Workflow,
  type WorkflowStepState,
  type WorkflowInitData,
  type PersonalDetails,
  type FinancingCalculatorDetails,
  type TradeInDetails,
  type ApplicantFinancingDetails,
  type CoApplicantFinancingDetails,
  type FinanceApplicationLenderDecisionDetails,
  type DocumentDetails,
  type PaymentDetails,
  type CheckoutDetails,
  type CoverageDetails,
  type BusinessDetailsDto
} from 'api/types'

import { SpinnerInstance } from 'store/spinner'
import { WorkflowApiProvider } from 'api/workflow.api'
import { GET_PERSONAL_DETAILS } from 'api/defaults/workflow'
import { RequestConfig } from 'api/types/utility.types'
import { CustomerAppBaseUrl } from 'routing/constants'

import { AlertInstance } from './alert'

export interface WorkflowData {
  workflow: Workflow | null
  activeStep: WorkflowStep | null
  errorStatus?: number | null
}

const defaultRequestConfig: RequestConfig = { showError: true }

class WorkflowStore {
  workflow: Workflow | null = null
  steps: WorkflowStep[] = []
  activeStep: WorkflowStep | null = null

  isCarUnavailableDialogVisible: boolean = false
  _requestLock: boolean = false

  /**
   * Used to fetch workflow by id or the latest not completed (active) workflow
   * @param dealId Deal Id
   * @param config Request config (by default contains config to show error to the user)
   */
  getWorkflow = async (dealId?: number, config: RequestConfig = defaultRequestConfig): Promise<WorkflowData> => {
    let errorStatus: number | null = null
    this.isCarUnavailableDialogVisible = false

    try {
      const workflow = (dealId == null || isNaN(Number(dealId)))
        ? await WorkflowApiProvider.info()
        : await WorkflowApiProvider.getWorkflowById(Number(dealId))

      if (workflow != null) {
        this.workflow = {
          ...workflow,
          workflowSteps: workflow.workflowSteps.sort((x: any, y: any) => x.order - y.order)
        }

        this.steps = this.workflow.workflowSteps ?? []

        this.activeStep = this.workflow.workflowSteps
          .find(item => item.workflowStepState === 'Pending') ?? null
      }
    } catch (e: any) {
      errorStatus = e.response?.status

      /**
       * handling of 404 is also a case of a displaying error flow
       */
      if (config.showError === true) {
        if (e.response?.status === 404) {
          this.isCarUnavailableDialogVisible = true
        } else {
          // when we check during signup current workflow we want it to be made without any error presenting for the user
          AlertInstance.addAlert({ data: 'Failed to fetch workflow', key: Date.now() })
        }
      }
    }

    return {
      workflow: this.workflow,
      activeStep: this.activeStep,
      errorStatus
    }
  }

  initWorkflow = async (data: WorkflowInitData): Promise<WorkflowData> => {
    if (isNaN(Number(data.dealerId))) {
      return {
        workflow: this.workflow,
        activeStep: this.activeStep
      }
    }

    let dealId: number | undefined

    this._requestLock = true
    SpinnerInstance.startSpinner()

    try {
      dealId = await WorkflowApiProvider.init(data)
    } catch {
      AlertInstance.addAlert({ data: 'Failed to create workflow', key: Date.now() })
    }

    await this.getWorkflow(dealId)

    this._requestLock = false
    SpinnerInstance.stopSpinner()

    return {
      workflow: this.workflow,
      activeStep: this.activeStep
    }
  }

  // #region SELECTOR UTILS
  // **************************

  getStepByName = (stepId: string): WorkflowStep | null => {
    const step = this.workflow?.workflowSteps
      .find(item => item.workTemplateStep === stepId)

    if (step === undefined && this.workflow != null) {
      console.warn(`Step "${stepId}" is not found in the current workflow.`)
    }

    return step ?? null
  }

  getStepState = (step: string | WorkflowStep): WorkflowStepState | null => {
    const stepData = typeof step === 'string' ? this.getStepByName(step) : step
    return stepData?.workflowStepState ?? null
  }

  getStepByUrl = (url: string): WorkflowStep | null => {
    const replacedUrl = url.replace(`/${CustomerAppBaseUrl}`, '')

    return this.workflow?.workflowSteps?.find((item) => {
      const stepId = String(item.workTemplateStep).toLowerCase()
      return stepId === replacedUrl.split('/')[1].toLowerCase()
    }) ?? null
  }

  getStepIdx = (step?: WorkflowStep | string): number => {
    const resolvedStepName = step === undefined
      ? this.activeStep
      : typeof step === 'string'
        ? step
        : step.workTemplateStep
    const stepIdx = this.workflow?.workflowSteps
      .findIndex(item => item.workTemplateStep === resolvedStepName)

    return stepIdx ?? -1
  }

  isPending (step: string | WorkflowStep): boolean {
    return this.getStepState(step) === 'Pending'
  }

  isCompleted (step: string | WorkflowStep): boolean {
    return this.getStepState(step) === 'Completed'
  }

  isSkipped (step: string | WorkflowStep): boolean {
    return this.getStepState(step) === 'Skipped'
  }

  isInactive (step: string | WorkflowStep): boolean {
    return this.getStepState(step) === 'Inactive'
  }

  // #endregion SELECTOR UTILS
  // **************************

  get personalDetails (): PersonalDetails {
    return this.workflow?.personalDetails ?? GET_PERSONAL_DETAILS()
  }

  get tradeInDetails (): TradeInDetails | null {
    return this.workflow?.tradeInDetails ?? null
  }

  get creditCalculatorDetails (): FinancingCalculatorDetails | null {
    return this.workflow?.financingCalculatorDetails ?? null
  }

  get applicantDetails (): ApplicantFinancingDetails | null {
    return this.workflow?.applicantFinancingDetails ?? null
  }

  get coApplicantDetails (): CoApplicantFinancingDetails | null {
    return this.workflow?.coApplicantFinancingDetails ?? null
  }

  get coApplicantFinancingDetails (): CoApplicantFinancingDetails | null {
    return this.workflow?.coApplicantFinancingDetails ?? null
  }

  get lenderDecisionDetails (): FinanceApplicationLenderDecisionDetails | null {
    return this.workflow?.financeApplicationLenderDecisionDetails ?? null
  }

  get documents (): DocumentDetails[] {
    return this.workflow?.documents ?? []
  }

  get checkoutDetails (): CheckoutDetails | null {
    return this.workflow?.checkoutDetails ?? null
  }

  get paymentDetails (): PaymentDetails[] {
    return this.workflow?.paymentDetails ?? []
  }

  get coverageDetails (): CoverageDetails[] {
    return this.workflow?.coverageDetails ?? []
  }

  get hasDelivery (): boolean {
    return this.workflow?.checkoutDetails?.hasDelivery ?? false
  }

  get businessDetails (): BusinessDetailsDto | null {
    return this.workflow?.businessDetails ?? null
  }

  constructor () {
    makeObservable(this, {
      getWorkflow: action,
      initWorkflow: action,
      workflow: observable,
      steps: observable,
      activeStep: observable,
      personalDetails: computed,
      applicantDetails: computed,
      tradeInDetails: computed,
      coApplicantDetails: computed,
      coApplicantFinancingDetails: computed,
      lenderDecisionDetails: computed,
      creditCalculatorDetails: computed,
      checkoutDetails: computed,
      paymentDetails: computed,
      hasDelivery: computed
    })
  }
}

export const WorkflowInstance = new WorkflowStore()
export const WorkflowCTX = createContext(WorkflowInstance)

export default WorkflowCTX
