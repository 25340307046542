import { type FC } from 'react'
import { cnx, Dropdown2 } from '@carfluent/common'
import SvgIcon from 'website/components/_base/SvgIcon'
import useMediaQueryByBreakpoints from 'website/hooks/useMediaQueryByBreakpoints'

interface DealerInfoSwitcherProps {
  className?: string
  dealershipNames: string[]
  activeIndex: number
  onClick: (index: number) => void
}

const MIN_FULL_VIEW_POINT = 768

const DealerInfoSwitcher: FC<DealerInfoSwitcherProps> = ({
  activeIndex,
  className,
  dealershipNames,
  onClick
}) => {
  const { isMatchingBreakpoints } = useMediaQueryByBreakpoints([MIN_FULL_VIEW_POINT])

  return (
    <div className={cnx('DealerInfoSwitcher', className)}>
      {
        isMatchingBreakpoints === true
          ? dealershipNames.map((name, i) => (
            <div
              key={name}
              className={cnx('dealer-info-switcher-item', i === activeIndex && 'active')}
              onClick={() => onClick(i)}
            >
              <span>{name}</span>
              <SvgIcon type='arrowRight' />
            </div>
          ))
          : (
            <Dropdown2
              className='dealer-info-switcher-dropdown'
              id='dealer-info-switcher-dropdown'
              disableClearable
              mode='select'
              onChange={(_id, value) => {
                onClick(dealershipNames.indexOf(value ?? ''))
              }}
              options={dealershipNames}
              value={dealershipNames[activeIndex]}
            />
            )
      }
    </div>
  )
}

export default DealerInfoSwitcher
