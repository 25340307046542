import coupeImg from 'website/assets/bs-coupe.png'
import crossoverImg from 'website/assets/bs-crossover.png'
import convertibleImg from 'website/assets/bs-convertible.png'
import hatchbackImg from 'website/assets/bs-hatchback.png'
import minivanImg from 'website/assets/bs-minivan.png'
import truckImg from 'website/assets/bs-pickuptruck.png'
import sedanImg from 'website/assets/bs-sedan.png'
import suvImg from 'website/assets/bs-suv.png'
import wagonImg from 'website/assets/bs-wagon.png'
import otherImg from 'website/assets/bs-other.png'

import isMultirooftop from 'website/utils/isMultirooftop'

import type {
  ActiveFilters,
  FilterBodyStyleKey,
  FilterName,
  FilterRangeKey,
  FilterRenderType,
  GeneratedFilter,
  RangeFilterConfig,
  SortOption,
  VehiclesSorting
} from './appTypes/filters'
import { BODY_STYLES, COLORS, DRIVETRAINS, FUELTYPES, TRANSMISSIONS } from './constants'

function getFromMap<K, V> (map: Map<K, V>, key: K): V {
  const result = map.get(key)
  if (result === undefined) {
    throw new Error(`Key not found: ${String(key)}`)
  }
  return result
}

const sortOptions: SortOption[] = [
  { sortField: 'make', sortOrder: 'Ascending', title: 'Make A-Z' },
  { sortField: 'createdDate', sortOrder: 'Descending', title: 'Recently added' },
  { sortField: 'salePrice', sortOrder: 'Ascending', title: 'Price: lowest first' },
  { sortField: 'salePrice', sortOrder: 'Descending', title: 'Price: highest first' },
  { sortField: 'year', sortOrder: 'Descending', title: 'Year: newest first' },
  { sortField: 'year', sortOrder: 'Ascending', title: 'Year: oldest first' },
  { sortField: 'mileage', sortOrder: 'Ascending', title: 'Lowest mileage' }
]

if (isMultirooftop()) {
  sortOptions.unshift(
    { sortField: 'closest', sortOrder: 'Ascending', title: 'Closest' }
  )
}

export const VEHICLE_SORTING_OPTIONS = sortOptions

export const DEFAULT_SORTING: VehiclesSorting = {
  sortField: VEHICLE_SORTING_OPTIONS[0].sortField,
  sortOrder: VEHICLE_SORTING_OPTIONS[0].sortOrder
}

export const RANGE_FILTER_CONFIGS = new Map<FilterRangeKey, RangeFilterConfig>()
  .set('year', { min: 1990, max: new Date().getFullYear() + 1, step: 1 })
  .set('mileage', { min: 0, max: 500000, step: 1000 })
  .set('price', { min: 0, max: 500000, step: 1000 })

export const BODY_STYLE_MAP = new Map<FilterBodyStyleKey, string>()
  .set('Coupe', coupeImg)
  .set('Crossover', crossoverImg)
  .set('Convertible', convertibleImg)
  .set('Hatchback', hatchbackImg)
  .set('Minivan/Van', minivanImg)
  .set('Pickup', truckImg)
  .set('Sedan', sedanImg)
  .set('SUV', suvImg)
  .set('Wagon', wagonImg)
  .set('Other', otherImg)

export const VEHICLE_FILTERS_TYPE_MAP = new Map<FilterName, FilterRenderType>()
  .set('year', 'range')
  .set('mileage', 'range')
  .set('price', 'range')
  .set('make', 'singleDropdown')
  .set('model', 'multiDropdown')
  .set('trim', 'singleDropdown')
  .set('bodyStyle', 'tile')
  .set('dealerId', 'list')
  .set('exteriorColor', 'color')
  .set('interiorColor', 'color')
  .set('transmission', 'list')
  .set('drivetrain', 'list')
  .set('fuelType', 'list')

/**
 * order of keys represents order of filters in UI.
 * Map guarantees order of keys.
 */
export const FILTERS_CONFIG = new Map<FilterName, GeneratedFilter>()
  .set('make', {
    id: 'make',
    name: 'Make',
    config: { items: [] },
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'make')
  })
  .set('model', {
    id: 'model',
    name: 'Model',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'model'),
    config: { items: [] },
    isDisabled: (appliedFilters?: ActiveFilters) => {
      const makes = appliedFilters?.make?.items
      return makes == null || makes.length === 0
    }
  })
  .set('price', {
    id: 'price',
    name: 'Price',
    startAdornment: '$',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'price'),
    config: { from: null, to: null },
    ...RANGE_FILTER_CONFIGS.get('price')
  })
  .set('mileage', {
    id: 'mileage',
    name: 'Mileage',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'mileage'),
    config: { from: null, to: null },
    ...RANGE_FILTER_CONFIGS.get('mileage')
  })
  .set('bodyStyle', {
    id: 'bodyStyle',
    name: 'Body style',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'bodyStyle'),
    config: { items: BODY_STYLES.map(name => ({ name, count: 0 })) }
  })
  .set('dealerId', {
    id: 'dealerId',
    name: 'Location',
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'dealerId'),
    config: { items: [] }
  })
  .set('year', {
    id: 'year',
    name: 'Year',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'year'),
    config: { from: null, to: null },
    ...RANGE_FILTER_CONFIGS.get('year')
  })
  .set('trim', {
    id: 'trim',
    name: 'Trim',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'trim'),
    config: { items: [] },
    isDisabled: (appliedFilters?: ActiveFilters) => {
      const models = appliedFilters?.model?.items
      return models == null || models.length === 0
    }
  })
  .set('exteriorColor', {
    id: 'exteriorColor',
    name: 'Exterior color',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'exteriorColor'),
    config: { items: COLORS.map(name => ({ name, count: 0 })) }
  })
  .set('interiorColor', {
    id: 'interiorColor',
    name: 'Interior color',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'interiorColor'),
    config: { items: COLORS.map(name => ({ name, count: 0 })) }
  })
  .set('transmission', {
    id: 'transmission',
    name: 'Transmission',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'transmission'),
    config: { items: TRANSMISSIONS.map(name => ({ name, count: 0 })) }
  })
  .set('drivetrain', {
    id: 'drivetrain',
    name: 'Drivetrain',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'drivetrain'),
    config: { items: DRIVETRAINS.map(name => ({ name, count: 0 })) }
  })
  .set('fuelType', {
    id: 'fuelType',
    name: 'Fuel type',
    isCollapsible: true,
    type: getFromMap(VEHICLE_FILTERS_TYPE_MAP, 'fuelType'),
    config: { items: FUELTYPES.map(name => ({ name, count: 0 })) }
  })
