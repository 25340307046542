import { MouseEvent, RefObject, useCallback, useEffect, useRef } from 'react'
import type { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types'
import { useDrag } from '@use-gesture/react'
import useWidthResize from 'website/hooks/useWidthResize'
import { bouncedAnimate, calcItemWidth, translateX } from './utils'
import { getCssVars } from 'website/configs'
import { onlyDigits } from 'website/utils/onlyDigits'

type Direction = 1 | 0 | -1 // left | neutral | right

type PositionChangeHandler = (index: number) => void

export interface UseGalleryItemsProps {
  itemsLength: number
  autoSwipeEnabled?: boolean
  isActive?: boolean
  selected?: number
  itemWidth?: string
  swipeThreshold?: number
  isKeyboardFriendly?: boolean
  stopPropagation?: boolean
  bounceDelay?: number
  showDots?: boolean
  onSelect?: PositionChangeHandler
  onClick?: (selected?: number, e?: MouseEvent<HTMLElement>) => void
}

export interface UseGalleryItemsReturn {
  elRef: RefObject<HTMLDivElement>
  dotsElRef: RefObject<HTMLDivElement>
  isLeftArrowDisable: boolean
  isRightArrowDisable: boolean
  onClick: (e: MouseEvent<HTMLElement>) => void
  onMoveLeft: () => void
  onMoveRight: () => void
  bind: (...args: any[]) => ReactDOMAttributes
}

// px
export const ITEM_WIDTH = '100px'
export const SWIPE_THRESHOLD = 6
export const MAX_BOUNCE_OFFSET = 100

// ms
export const BOUNCE_DELAY = 150

const getDotWidth = (): number => {
  const { galeryDotSize, galeryDotGap } = getCssVars()
  return onlyDigits(galeryDotSize) + onlyDigits(galeryDotGap)
}

const useGalleryItems = ({
  itemsLength,
  onSelect,
  onClick: handleClick,
  selected = 0,
  isActive = false,
  autoSwipeEnabled = true,
  isKeyboardFriendly = false,
  stopPropagation = false,
  itemWidth = ITEM_WIDTH,
  swipeThreshold = SWIPE_THRESHOLD,
  bounceDelay = BOUNCE_DELAY,
  showDots = false
}: UseGalleryItemsProps): UseGalleryItemsReturn => {
  const { elRef, width } = useWidthResize()

  const prevPos = useRef(selected)
  const offsetRef = useRef(0)
  const hasDraggedRef = useRef(false)
  const dotsElRef = useRef<HTMLDivElement>(null)
  const isKeyboardListenerAttachedRef = useRef(false)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onMove = useCallback((dir: Direction, selectOnDrag: boolean, pos?: number): void => {
    const el = elRef.current

    if (dir === 0 || el == null) {
      return
    }

    const animate = window.requestAnimationFrame

    const itemWidthVal = calcItemWidth(itemWidth, width)
    const bounceOffset = Math.min(MAX_BOUNCE_OFFSET, itemWidthVal / 2)

    /**
     * we scroll only by one item from the previous position
     */

    const maxItemsOnScreen = Math.min(itemsLength, Math.floor(width / itemWidthVal))
    const rightLimit = -1 * (itemsLength - maxItemsOnScreen) * itemWidthVal

    const step = pos == null ? 1 : Math.abs(pos - prevPos.current)
    offsetRef.current += dir * itemWidthVal * step

    /**
     * we need to invert direction for 'selected index'
     */
    const nextIdxRaw = prevPos.current + dir * -1 * step
    const nextIdx = Math.min(itemsLength - 1, Math.max(nextIdxRaw, 0))

    if (selectOnDrag) {
      onSelect?.(nextIdx)
    }

    /**
     * we do not show dots animation when we reached the end on the previous step
     */
    if (showDots && nextIdxRaw > -1 && nextIdxRaw < itemsLength) {
      animate(() => {
        if (dotsElRef.current != null) {
          dotsElRef.current.style.left = `${nextIdx * -1 * getDotWidth()}px`
        }
      })
    }

    if (offsetRef.current > 0) {
      offsetRef.current = 0
      const isBounced = hasDraggedRef.current && nextIdxRaw < 0

      bouncedAnimate(animate, el, offsetRef.current, bounceOffset, -1, isBounced, bounceDelay)
    } else if (offsetRef.current < rightLimit) {
      offsetRef.current = rightLimit
      const isBounced = hasDraggedRef.current && nextIdxRaw > itemsLength - 1

      bouncedAnimate(animate, el, offsetRef.current, bounceOffset, 1, isBounced, bounceDelay)
    } else {
      animate(() => {
        el.style.transform = translateX(offsetRef.current)
      })
    }
  }, [
    width,
    itemWidth,
    bounceDelay,
    itemsLength,
    showDots,
    onSelect
  ])

  const onClick = useCallback((e: MouseEvent<HTMLElement>) => {
    /**
     * we need this to avoid conflicts between different Galleries on the screen.
     * Conflicts relate to handling open/close on outside click event for example.
     */
    if (stopPropagation) {
      e.stopPropagation()
    }

    const idx = Number(e.currentTarget.getAttribute('data-idx'))

    if (!isNaN(idx)) {
      onSelect?.(idx)
    }

    handleClick?.(idx, e)
  }, [onSelect, handleClick, stopPropagation])

  const onMoveLeft = useCallback((e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation()

    hasDraggedRef.current = true
    onMove(1, true)
  }, [onMove])

  const onMoveRight = useCallback((e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation()

    hasDraggedRef.current = true
    onMove(-1, true)
  }, [onMove])

  const handleKeyDown = useCallback((e: any): void => {
    if (e.key === 'ArrowLeft') {
      onMoveLeft()
    } else if (e.key === 'ArrowRight') {
      onMoveRight()
    }
  }, [onMoveLeft, onMoveRight])

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    if (!isActive || !isKeyboardFriendly || isKeyboardListenerAttachedRef.current) {
      return
    }
    document.addEventListener('keydown', handleKeyDown)
    isKeyboardListenerAttachedRef.current = true

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      isKeyboardListenerAttachedRef.current = false
    }
  }, [handleKeyDown, isKeyboardFriendly, isActive])

  const bind = useDrag((dragProps) => {
    const { active, movement: [mx], direction: [xDir], cancel } = dragProps

    if (active && Math.abs(mx) > swipeThreshold) {
      hasDraggedRef.current = true
      onMove(xDir as Direction, true)
      cancel()
    }
  }, { filterTaps: true })

  /**
   * move to selected after resize hook
   */
  useEffect(() => {
    const el = elRef.current

    if (el == null || width === 0) {
      return
    }

    const itemWidthVal = calcItemWidth(itemWidth, width)
    const maxItemsOnScreen = Math.min(itemsLength, Math.floor(width / itemWidthVal))
    const rightLimit = -1 * (itemsLength - maxItemsOnScreen) * itemWidthVal

    offsetRef.current = Math.max(rightLimit, -1 * itemWidthVal * prevPos.current)

    window.requestAnimationFrame(() => {
      el.style.transform = translateX(offsetRef.current)
    })
  }, [width, itemsLength, itemWidth])

  /**
   * move to selected hook
   */
  useEffect(() => {
    if (prevPos.current === selected || !autoSwipeEnabled) {
      return
    }

    if (hasDraggedRef.current) {
      hasDraggedRef.current = false
      return
    }

    const dir = prevPos.current > selected ? 1 : -1
    onMove(dir, false, selected)
  }, [selected, autoSwipeEnabled, onMove])

  /**
   * prevPos.current must be updated only after it was used by all other hooks.
   * This way we preserve its correct logic. That is why this hook goes last.
   */
  useEffect(() => {
    if (autoSwipeEnabled) {
      prevPos.current = selected
    }
  }, [selected, autoSwipeEnabled])

  // ========================================== //

  return {
    elRef,
    dotsElRef,
    isLeftArrowDisable: selected === 0,
    isRightArrowDisable: selected === itemsLength - 1,
    onMoveLeft,
    onMoveRight,
    onClick,
    bind
  }
}

export default useGalleryItems
