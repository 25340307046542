import { useCallback } from 'react'
import { useForm, type UseFormReturn } from '@carfluent/common'

import type { FullAddressParts } from 'components/AddressAutocomplete/types'
import { FinancingSubRoutes } from 'website/routing/constants'

import { type ErrTouchShortcuts, useSubmitGetPrequalifiedFormAction } from './useSubmitGetPrequalifiedFormAction'
import validationRules from './validator'
import { FormIds } from './constants'

export type PrequalifiedFormData = Omit<Store.PrequalifiedFormValues, 'lastUpdateTs'>

interface UseGetPrequalifiedFormReturn extends UseFormReturn<PrequalifiedFormData, ErrTouchShortcuts> {
  onChangeDate: (id: string, date: Date | null) => void
  onChangeAddress: (addr: FullAddressParts | null) => void
  isLoading: boolean
}

export const useGetPrequalifiedForm = (): UseGetPrequalifiedFormReturn => {
  const {
    submitAction,
    onActionResult,
    baseValues
  } = useSubmitGetPrequalifiedFormAction<PrequalifiedFormData>({
    errorNavigationRoute: FinancingSubRoutes.SubIncome,
    shouldCreateLead: true
  })

  const form = useForm<PrequalifiedFormData, ErrTouchShortcuts>({
    baseValues,
    submitAction,
    validationRules,
    onActionResult
  })

  const onChangeAddress = useCallback((addr: FullAddressParts | null) => {
    form.onChange(FormIds.Address, addr)
  }, [form.onChange])

  const onChangeDate = useCallback((id: string, date: Date | null) => {
    form.onChange(id, date)
  }, [form.onChange])

  return {
    ...form,
    onChangeAddress,
    onChangeDate,
    isLoading: form.isSubmitting
  }
}
